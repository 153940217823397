.header-student {
  padding-bottom: 20px;
  padding-top: 20px;
  column-gap: 30px;
  background-color: $green;
  display: flex;
  justify-content: center;
  &--active {
    margin-top: -10px;
    margin-bottom: -10px;
    border: solid 1px $black;
    background-color: $golden;
    border-radius: 8px;
    padding: 9px 15px 9px 16px;
  }
  @include respond-below(sm) {
    display: none;
  }
  &.--mobile {
    @include respond-below(sm) {
      display: flex;
    }
    @include respond-above(sm) {
      display: none;
    }
  }
  &__dropdown {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
    &__button.--menu {
      padding: 11px 15px;
      width: 100%;
      display: flex;
      border-radius: 8px;
      border: solid 1px $black;
      font-weight: bold;
      background-color: $golden;
      justify-content: space-between;
      align-items: center;
    }
  }
}
