.form-group {
  label {
    margin-bottom: 6px;
    font-weight: bold;
    span[title='required'] {
      color: $medium-red;
    }
  }
  .form-control {
    padding: 15px 20px 15px 20px;
    border-radius: 10px;
    border: solid 1px $gray;
    &:focus {
      border: solid 1px $gray;
      box-shadow: none;
    }
  }
  .form-control::placeholder {
    color: #b8b8b8;
    opacity: 1;
    font-size: 16px;
  }
}

input {
  font-family: $font-family-body !important;
}

.field-custom {
  .field {
    padding: 12px 20px;
    font-weight: 600;
    outline: none;
    margin: 10px 0 5px 0;
    border-radius: 10px;
    border: solid 1px $black;
    cursor: text;
    box-shadow: 5px 5px $white, 5px 5px 0px 1px $black;

    &:focus {
      border: solid 1px $black;
      box-shadow: 5px 5px $white, 5px 5px 0px 1px $black;
    }
    @include respond-below(sm) {
      &.--mobile {
        padding: 12px 0px;
      }
    }

    &.intl-tel-input {
      input#contact_info_phone_number {
        width: 100%;
        border: none;
        color: $black;
        font-weight: 600;
        &:focus,
        textarea:focus,
        select:focus {
          outline: none;
        }
      }
    }

    .iti__selected-flag {
      border-radius: 6px 0px 0px 6px;
      background-color: $white;
    }

    &::-webkit-input-placeholder {
      color: $light-gray;
      font-weight: 300;
    }

    &::-moz-placeholder {
      color: $light-gray;
      font-weight: 300;
    }

    &__radio {
      &--checked {
        background-color: $pale-orange;
      }
      &__check {
        margin-right: 15px;
        &:after {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: -4px;
          left: 0;
          position: relative;
          background-color: $white;
          content: '';
          display: inline-block;
          visibility: visible;
          border: solid 1px $black;
        }

        &:checked:after {
          background-color: #8ef882;
        }
      }
      &__label {
        font-weight: bold;
        border: solid 1px $black;
        padding: 13px 30px 13px 20px;
        margin-right: 20px;
        border-radius: 10px;
        box-shadow: 5px 5px $white, 5px 5px 0px 1px $black;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }
    }

    &__icon {
      position: absolute;
      top: 27px;
      right: 30px;
    }
    &.placeholder {
      opacity: unset;
    }
  }

  .invalid-feedback {
    padding-top: 5px;
  }

  [id*='otp'] {
    text-align: center;
  }
}

.form-control.is-invalid {
  box-shadow: 5px 5px #ffffff, 5px 5px 0px 1px #dc3545;
}

select.placeholder {
  color: gray;
  background-color: white;
}
