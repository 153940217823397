.registration {
  padding-top: 60px;
  padding-bottom: 100px;
  background: $white-smoke;

  &__form {
    max-width: 570px;
    margin: auto;
  }
}

.progressing {
  width: 100%;
  border: 1px dashed $black;
  background-color: #f1f1f1;
  height: 20px;
  border-radius: 13.5px;
  margin-bottom: 20px;
  position: relative;
  &--wrapper {
    position: absolute;
    max-width: 570px;
    left: -2px;
    right: -2px;
    margin: auto;
  }

  &--children,
  &--basic {
    width: 25%;
  }

  &--parent,
  &--identity {
    width: 50%;
  }

  &--2fa {
    width: 75%;
  }

  &--payment {
    width: 100%;
  }

  &--completed {
    border: 1px solid $black;
    height: 20px;
    background-color: $golden;
    border-radius: 13.5px;
    margin-top: -1px;
  }
}
