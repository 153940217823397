.app_pagination {
  display: flex;
  justify-content: end;
  background: $white;
  cursor: default;
  a,
  span,
  em {
    padding: 0.5rem;
    color: $black;
    border-radius: 10px;
    font-size: 16px;
    border: solid 1px $black;
    background-color: #f1f1f1;
    box-shadow: 5px 5px #acacac, 5px 5px 0px 1px $black;
    margin-right: 0.75rem;
  }
  .disabled {
    color: $grey;
  }
  .current {
    background-color: $golden;
    box-shadow: 5px 5px $blue, 5px 5px 0px 1px $black;
  }
  a {
    text-decoration: none;
  }

  &.--admin, &.--leaderboard {
    background-color: unset;
    margin-top: 30px;
  }
  &.--leaderboard {
    justify-content: center;
    &.--mobile {
      .pagination {
        @include respond-below(xs) {
          display: flex;
          width: 150px;
          justify-content: space-around;
        }
      }
    }
  }

  @include respond-below(xs) {
    display: none;
  }

  &.--mobile {
    @include respond-above(xs) {
      display: none;
    }
    @include respond-below(xs) {
      display: flex;
    }
  }
}
