.contact-us {
  background-size: auto;
  background-repeat: no-repeat;
  background-image: url('~homepage/bg-contact-us.jpg');
  padding-top: 50px;
  @include respond-below(md) {
    padding-top: 20px;
  }
  &__title {
    font-family: $font-family-title;
    font-weight: bold;
    color: $primary;
  }
  &__select {
    &.--custom {
      width: 150px;
      border-radius: 10px;
      border: solid 1px $gray92;
      background-color: $white-smoke;
      &:focus {
        border: solid 1px $gray92;
        box-shadow: none;
      }
    }
  }
  &__map {
    height: 400px;
    border-radius: 20px;
    border: solid 1px $gray92;
    width: 100%;
    @include respond-below(sm) {
      height: 200px;
    }
  }
  &__card {
    padding: 30px;
    border-radius: 20px;
    background-color: $white-smoke;
    @include respond-below(md) {
      margin-top: 30px;
    }
  }
  .form-group label{
    height: 22px;
  }
}
