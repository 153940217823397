$white: #FFFFFF;
$black: #304c5f;
$orange: #fa744e;
$orange2: #fa754e;
$dark: #2f2f2f;
$green: #088a8b;
$gray: #f0f0f0;
$white-smoke: #f9f9f9;
$blue: #0085c3;
$blue2: #0071a6;
$light-green: #43d988;
$grey: #838383;
$golden: #ffc866;
$pale-orange: #fff7e8;
$light-gray: #bcbcbc;
$light-red: #ff5b5b;
$medium-red: #fa6464;
$error-red: #dc3545;
$error-background-red: #dc35451a;
$gray92: #ebebeb;
$gray55: #555;

$font-size-header: 80px;
$font-size-header-lg: 60px;
$font-size-header-md: 40px;
$font-size-logo: 32px;
$font-size-logo-mobile: 24px;
$font-size-body: 18px;
$h1-font-size: 46px;
$h2-font-size: 40px;
$h3-font-size: 30px;
$h4-font-size: 24px;
$h5-font-size: 20px;
$h6-font-size: 18px;
$font-size-normal: 16px;
$font-size-small: 14px;
$font-family-title: 'Libre Baskerville';
$font-family-body: 'Nunito';
$btn-font-size: 16px;

// custom boostrap default color
$navbar-light-color: $dark;
$navbar-light-active-color: $orange;
$success: $green;
$primary: $orange2;
$info: $blue2;
$hr-color: $gray;
$secondary: $white-smoke;
$hr-opacity: 1;
$body-color: $black;
$muted: $dark;
$dark: $black;

// custom boostrap fontsize

:root {
  --bs-body-font-size: 16px !important;
  --bs-body-font-family: 'Nunito' !important;
  --bs-body-color: #304c5f !important;
  --bs-body-font-weight: 300 !important;
}
