$badgeWidth: var(--width-reward);
$kinds: 'bronze', 'colorSilver', 'colorGold', 'platinum', 'diamond';

.dashboard {
  overflow: hidden;
  &__card {
    border-radius: 30px;
    background-color: $white-smoke;
    padding: 30px;
    margin-bottom: 20px;
    @include respond-below(sm) {
      padding: 15px;
    }
    &__avatar {
      object-fit: cover;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      margin-top: -55px;
      margin-bottom: 20px;
      @include respond-below(sm) {
        width: 100px;
        height: 100px;
        margin-top: -35px;
      }
    }
    &__button {
      &.--custom {
        min-width: 170px;
        height: 46px;
        @include respond-below(sm) {
          min-width: 150px;
          height: 40px;
          font-size: 14px;
        }
      }
    }
    &__subscription {
      @include respond-below(sm) {
        flex-direction: column;
        row-gap: 10px;
      }
    }
  }
  &__hat {
    background-image: url('~dashboard/hat.svg');
    width: 155px;
    height: 80px;
    margin-bottom: 54px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -1;
    top: -40px;
    left: -60px;
    @include respond-below(sm) {
      display: none;
    }
  }
  &__pencil {
    background-image: url('~dashboard/pencil.svg');
    width: 80px;
    height: 160px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    z-index: -1;
    bottom: 0px;
    right: -40px;
    @include respond-below(sm) {
      display: none;
    }
  }
  &__icon {
    width: 17px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    &.--calendar {
      background-image: url('~dashboard/calendar.svg');
    }
    &.--level {
      margin-top: 5px;
      background-image: url('~dashboard/grade_level.svg');
      width: 24px;
      height: 16px;
    }
    &.--marker {
      background-image: url('~dashboard/marker.svg');
    }
  }
  &__icon-hand {
    width: 32px;
    height: 32px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;
    background-image: url('~dashboard/hand.svg');
    @include respond-below(md) {
      width: 18px;
      height: 18px;
    }
  }
  &__button {
    border-radius: 15px;
    border: solid 1px $black;
    background-color: $golden;
    width: 44px;
    height: 44px;
    @include respond-below(xs) {
      display: none;
    }
  }
  .lesson__card__item {
    align-items: center;
    &__info {
      display: flex-direction;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }
    &__group {
      max-height: unset;
      @include respond-below(xs) {
        width: 100%;
      }
    }

    &__image {
      width: 147px;
      @include respond-below(sm) {
        width: 100%;
        height: 155px;
      }
    }
    &:hover {
      border: none;
      background-color: #f1f1f1;
      padding: 10px 15px;
    }
  }
  .progressing {
    width: 98%;

    &--wrapper {
      max-width: 100%;
      left: -1px;
      top: -1px;
      right: 0;
    }
    &--completed {
      margin-top: 0;
      &.--badge {
        width: $badgeWidth;
        background-color: #f1f1f1;
      }
    }
    &--bar {
      border-radius: 4px;
      border: solid 1px $black;
      background-color: $blue;
      width: 8px;
      height: 40px;
      position: absolute;
      top: -10px;
      &:hover {
        cursor: pointer;
      }
      &.--badge {
        left: calc(#{$badgeWidth} - 8px);
      }
    }
  }
  &__rewards {
    min-width: 900px;
    &__icon {
      width: 80px;
      height: 80px;
      background-size: contain;
      background-repeat: no-repeat;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @each $kind in $kinds {
        &.--#{$kind} {
          background-image: url('~dashboard/#{$kind}.svg');
        }
      }
    }

    .align-items-right {
      align-items: end;
    }
  }
}
