.profile {
  padding-top: 40px;
  padding-bottom: 30px;
  @include respond-below(sm) {
    padding-top: 20px;
  }
  &__tab {
    width: 200px;
    color: $black;
    margin-top: 10px;
    text-decoration: none;
    padding: 9px 20px;
    &.--active {
      border-radius: 8px;
      border: solid 1px $black;
      background-color: $golden;
      pointer-events: none;
    }
  }
  &__card {
    padding: 30px;
    border-radius: 20px;
    background-color: $white-smoke;
    @include respond-below(sm) {
      padding: 20px 15px;
    }
  }
  &__referral-code {
    padding: 12px 20px;
    font-weight: 600;
    margin: 10px 0 5px 0;
    border-radius: 10px;
    border: solid 1px $black;
    box-shadow: 5px 5px $blue, 5px 5px 0px 1px $black;
  }
  &__share-btn {
    margin-top: 20px;
    border-radius: 5px;
    border: solid 1px $black;
    background-color: $white;
    padding: 8px 12px 7px 13px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: $black;
  }
  &__copy-icon {
    background-size: contain;
    width: 14px;
    height: 14px;
    margin-right: 10px;
    background-image: url('~icon/copy.svg');
  }
}
