.my-transaction {
  padding-top: 40px;

  @include respond-below(xs) {
    padding-top: 0;
  }

  &__table {
    &__thead {
      &__tr {
        &__th {
          font-family: $font-family-body;
          font-size: 1rem;
          color: $black;
          font-weight: normal;
          padding: 20px 15px;

          // date
          &:first-child {
            padding-left: 30px;
            width: 230px;
          }

          // payment
          &:nth-child(3) {
            width: 150px;
          }

          // cost
          &:nth-child(4) {
            width: 100px;
          }

          // status
          &:last-child {
            padding-right: 30px;
            width: 100px;
          }
        }
      }
    }

    &__tbody {
      &__tr {
        border-radius: 20px;
        font-family: $font-family-body;
        font-size: 1rem;
        font-weight: bold;
        color: $black;

        &:not(:nth-child(2n)) {
          background-color: $white-smoke;
        }

        td:not(.white-space-cell) {
          padding: 20px 15px;
          width: fit-content;

          &:first-child {
            padding-left: 30px;
            border-radius: 20px 0 0 20px;
          }

          &:last-child {
            padding-right: 30px;
            border-radius: 0 20px 20px 0;
          }
        }
      }
    }
  }

  &__card-brand {
    background-image: url('~icon/payment/visa-card.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 20px;
    margin-right: 5px;
    &[name='mastercard'] {
      background-image: url('~icon/payment/master-card.svg');
    }
    &[name='amex'] {
      background-image: url('~icon/payment/amex-card.svg');
    }
    &[name='unionpay'] {
      background-image: url('~icon/payment/union-pay-card.png');
    }
  }
}
