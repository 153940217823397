.cursor-pointer {
  cursor: pointer !important;
}

.text-grey {
  color: $grey;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.border-radius-30 {
  border-radius: 30px !important;
}

.text-light-green {
  color: $light-green;
}

.text-golden {
  color: $golden;
}

.text-red {
  color: $medium-red;
}

.fs-small {
  font-size: $font-size-small !important;
}

.fs-normal {
  font-size: $font-size-normal !important;
}

.flex-1 {
  flex: 1;
}

.btn-shadow {
  color: $black;
  min-width: 200px;
  height: 54px;
  border-radius: 10px;
  font-size: 16px;
  border: solid 1px $black;
  transition: all ease 0.2s;

  &--golden {
    background-color: $golden;
    box-shadow: 5px 5px $blue, 5px 5px 0px 1px $black;

    &.active, &.loading {
      background-color: $green;
      color: $white;
    }

    &.active {
      box-shadow: 2px 2px $blue, 2px 2px 0px 1px $black;
    }
  }

  &--gray {
    background-color: #f1f1f1;
    box-shadow: 5px 5px #acacac, 5px 5px 0px 1px $black;

    &.active {
      background-color: $light-gray;
      box-shadow: 2px 2px #acacac, 2px 2px 0px 1px $black;
    }
  }

  &--red {
    background-color: $medium-red;
    color: $white;
    box-shadow: 5px 5px #acacac, 5px 5px 0px 1px $black;

    &.active {
      background-color: $error-red;
      box-shadow: 2px 2px #acacac, 2px 2px 0px 1px $black;
    }
  }

  &--white {
    background-color: $white;
    color: $black;
    box-shadow: 5px 5px $blue, 5px 5px 0px 1px $black;

    &.active {
      background-color: $green;
      color: $white;
      box-shadow: 2px 2px $blue, 2px 2px 0px 1px $black;
    }
  }

  &.active {
    transform: translate(3px, 3px);
  }

  &.loading {
    pointer-events: none;
  }

  @include respond-below(sm) {
    min-width: 150px;
    height: 44px;
  }
}

.fit-unset {
  object-fit: unset;
}

.fit-cover {
  object-fit: cover;
}

.btn-close.--custom {
  position: absolute;
  right: 16px;
  top: 28px;
}

.select2-container {
  width: 100% !important;
}

.text-blur {
  color: #606060;
}

.network {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin: 1.5rem 0.5rem 1.5rem;
}

.button-copy {
  border: none;
  font-size: 1.5rem;
  background-color: white;
}

.btn-role {
  min-height: 120px;
  padding: 2rem;
  width: 100%;
  &:hover {
    background-color: #fff7e8;
    svg {
      fill: #8ef882;
    }
  }
}

.selected-file {
  background: #ddeeff;
  border-radius: 0.2em;
  margin-top: 1px;
  padding: 0.2em;
}

.selected-file input[type='file'] {
  display: none;
}

.icon-livestream {
  font-size: 30px;
  color: #206bc4;
}

.font-weight-700 {
  font-weight: 700;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.h-fit {
  height: fit-content !important;
}

.mx-n1 {
  margin: 0 -0.25rem;
}
