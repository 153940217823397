.devise-flash-container {
  max-width: 570px;
  margin: 0 auto;
}

.devise-form-container {
  margin-top: 5vh;

  @include respond-below(sm) {
    margin-top: 20px;
  }

  .logo {
    width: 210px;
  }

  .card {
    padding: 24px;
    max-width: 570px;
  }

  &__title {
    font-size: 22px;
  }

  .form-recaptcha {
    min-height: 78px;
  }
}
