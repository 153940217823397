.schedule {
  &__player {
    max-height: 362px;
    border-radius: 21.3px;
    border-width: 14px;
    width: 610px;
    @include respond-below(sm) {
      width: unset;
    }
  }
  .play-icon {
    width: 80px;
    height: 80px;
    box-shadow: 0 0 0px 10px rgba(250, 117, 78, 0.2);
    &__triangle {
      width: 18px;
      height: 18px;
    }
    @include respond-below(sm) {
      width: 40px;
      height: 40px;
      box-shadow: 0 0 0px 5px rgba(250, 117, 78, 0.2);
    }
  }
  .vjs-fluid {
    padding-top: unset;
  }
  &__card {
    padding: 30px;
    border-radius: 30px;
    background-color: $white-smoke;
  }
  &__carousel {
    &.--indicators {
      position: unset;
      margin: 0;
      &.--custom {
        width: 15px;
        height: 6px;
        margin: 12px 5px 31px 0px;
        border-radius: 3px;
        background-color: $gray92;
        border: none;
      }
      &.active {
        width: 50px;
        background-color: $blue;
      }
    }
  }
  &__title {
    font-family: $font-family-title;
    margin-right: 30px;
    font-weight: bold;
  }

  &__date {
    padding: 11px 19px;
    border-radius: 15px;
    border: solid 1px #088a8b;
    color: #088a8b;
    font-weight: bold;
    width: 70px;
    text-align: center;
    height: 70px;
    margin-right: 30px;
    font-size: 18px;
    @include respond-below(sm) {
      margin-bottom: 10px;
    }
  }
  &__group {
    display: flex;
    padding-bottom: 70px;
    @include respond-below(sm) {
      display: flex;
      flex-direction: column;
    }
  }
  &__watch {
    padding: 13px 23px;
    border-radius: 10px;
    background-color: #fa754e;
    color: white;
    width: fit-content;
    display: flex;
    margin: auto;
    margin-top: -77px;
    position: relative;
    cursor: pointer;
  }
  .close {
    position:absolute;
    right:-50px;
    top:-1.5rem;
    z-index:999;
    font-size:4rem;
    font-weight: normal;
    color: black;
    border: none;
    background: unset;;
    opacity:1;
  }
  &__video-modal {
    @include respond-below(xs) {
      max-width: 100%;
      height: 240px;
    }
  }
  &__modal {
    padding-top: 20vh;
    @include respond-below(xs) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  &__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 24px;
    height: 48px;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 18px;
  }
  .img-poster {
    height: 250px;
    filter:brightness(80%)
  }
}
