.two-factor-authentication {
  padding-top: 40px;
  padding-bottom: 30px;

  &__card {
    padding: 30px;
    border-radius: 20px;
    background-color: $white-smoke;
  }
}
