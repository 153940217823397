.header {
  font-size: $font-size-normal;

  &__avatar {
    object-fit: cover;
    width: 42px;
    height: 42px;
    border-radius: 50%;

    @include respond-below(md) {
      height: 30px;
      width: 30px;
    }
  }

  .select2-states {
    width: 110px;
  }
  .select2-selection--single {
    border: none !important;
    .flag-icon {
      height: 30px;
      width: 44px;
      margin-right: 5px;
    }
  }
  &__icon-cart {
    width: 27px;
    height: 34px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('~icon/payment/cart.svg');

    @include respond-below(md) {
      height: 30px;
      width: 30px;
    }

    &--custom-total {
      position: absolute;
      right: -15px;
      top: -15px;

      @include respond-below(md) {
        max-height: 25px;
        max-width: 25px;
        right: -10px;
        top: -10px;
      }
    }
  }

  .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: 0;
  }
}

.logo {
  font-size: $font-size-logo;
  color: $primary;
  font-weight: 900;
  @include respond-below(sm) {
    font-size: $font-size-logo-mobile;
  }
}
