.custom-confirm-modal {
  margin-top: 150px !important;

  .custom-completed-button {
    border: 1px solid;
    border-radius: 7px;
    font-weight: bold;
  }

  &__content {
    height: 300px;
    padding: 20px;

    &__header {
      border: none !important;
      justify-content: flex-end !important;

      &__cancel-btn {
        font-size: 20px;
        width: 40px;
        height: 35px;
      }
    }

    &__body {
      text-align: center;

      &__confirm-content {
        font-size: 23px;
        font-weight: bold;
      }

      &__wrapper-button-modal {
        text-align: center;
        margin-top: 50px;
        display: inline-flex;

        &__confirm-button {
          width: 120px;
          height: 45px;
          margin-right: 20px;
        }
      }
    }
  }
}
