.about {
  &__image {
    padding-bottom: 100px;
    display: inline-grid;
    width: 100%;
    filter: url(#round);

    @include respond-below(sm) {
      padding-bottom: 60px;
    }

    &::before {
      content: "";
      display: block;
      background-size: cover;
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
      background-image: url('~homepage/bg-about.jpeg');
      height: 540px;

      @include respond-below(md) {
        height: 480px;
      }
      @include respond-below(sm) {
        height: 410px;
        background-position: 30%;
      }
    }
  }

  &__title {
    text-align: center;
    font-family: $font-family-title;
    font-weight: bold;
    padding-bottom: 30px;
  }

  &__reasons {
    padding-top: 30px;
    padding-bottom: 100px;
    &__description {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__learning {
    background-color: $white-smoke;
    border-radius: 30px;
    padding-top: 80px;
    padding-bottom: 80px;
    &__description {
      max-width: 470px;
      margin: auto;
      padding-top: 30px;
      text-align: center;
    }
  }

  &__team {
    padding-top: 100px;
    padding-bottom: 84px;
    &--icon {
      color: $blue;
      width: 40px;
      height: 40px;
      padding: 10px;
      background-color: $white-smoke;
      border-radius: 10px;
    }
    &__member {
      max-width: 370px !important;
    }
  }
}
