$video-border: 20px;
$video-border-md: 10px;
$video-border-radius: 30px;
$video-border-radius-lg: 20px;
$video-border-radius-md: 10px;

$imgs-reason: (
  reason-1: url('~homepage/reason-4.png'),
  reason-2: url('~homepage/reason-1.png'),
  reason-3: url('~homepage/reason-6.png'),
  reason-4: url('~homepage/reason-5.png'),
  reason-5: url('~homepage/reason-2.png'),
  reason-6: url('~homepage/reason-3.png'),
);

.intro {
  position: relative;
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  color: $white;
  padding-bottom: 120px;
  &__title {
    padding-top: 40px;
    font-family: $font-family-title;
    font-weight: bold;
    padding-bottom: 20px;
  }
  &__icon {
    &.--custom {
      background: $golden;
    }
  }
  @include respond-below(xs) {
    margin-top: 10px;
    &__title {
      padding-top: 30px;
    }
  }
  @include respond-between(xs, md) {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    &__title {
      padding-top: 20px;
    }
  }
  @include respond-between(md, lg) {
    margin-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    &__title {
      padding-top: 20px;
    }
  }

  &__image {
    padding-bottom: 100px;
    left: 0;
    right: 0;
    z-index: -1;
    position: absolute;
    filter: url(#round);

    &::before {
      content: '';
      display: block;
      background-size: cover;
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
      clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
      background-image: url('~homepage/bg-intro.jpeg');
      height: 440px;

      @include respond-between(sm, md) {
        height: 310px;
      }
      @include respond-between(md, lg) {
        height: 360px;
      }
      @include respond-between(xs, sm) {
        height: 370px;
      }
      @include respond-below(xs) {
        height: 340px;
      }
    }
  }

  &__video {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: -125px;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
  }

  .cta {
    display: flex;
    padding: 10px 40px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 31px;
    font-weight: 500;
    color: white;
    background: #6225e6;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(-15deg);
    margin-top: 2rem;

    &:focus {
      outline: none;
    }

    &:hover {
      transition: 0.5s;
      box-shadow: 10px 10px 0 #fbc638;
      padding: 10px 45px;
      font-size: 35px;
    }
  }
}

.player-wrapper {
  position: relative;
  margin: auto;
  height: 460px;
  max-width: 860px;
  border: $video-border solid $black;
  border-radius: $video-border-radius;
  background-color: black;
  @include respond-between(md, lg) {
    height: 360px;
    width: 100%;
    border: $video-border-md solid $black;
    border-radius: $video-border-radius-lg;
  }
  @include respond-between(sm, md) {
    height: 300px;
    width: 100%;
    border: $video-border-md solid $black;
    border-radius: $video-border-radius-md;
  }
  @include respond-between(xs, sm) {
    height: 340px;
    max-width: 100%;
    border: $video-border-md solid $black;
    border-radius: $video-border-radius-md;
  }

  @include respond-below(xs) {
    height: 180px;
    max-width: 305px;
    margin: auto;
    border: $video-border-md solid $black;
    border-radius: $video-border-radius-md;
  }

  &.--footer-video::before {
    content: '';
    position: absolute;
    left: 25%;
    bottom: -40px;
    border-bottom: $video-border solid $golden;
    border-bottom-right-radius: $video-border-radius;
    border-bottom-left-radius: $video-border-radius;
    width: 50%;
    @include respond-below(lg) {
      bottom: -20px;
      border-bottom: $video-border-md solid $golden;
      border-bottom-right-radius: $video-border-radius-md;
      border-bottom-left-radius: $video-border-radius-md;
    }
  }

  .vjs-tech {
    border-radius: 10px;
  }

  .vjs-control-bar {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .vjs-poster {
    background-size: cover;
    border-radius: 10px;
  }

  .vjs-big-play-button {
    display: none !important;
  }

  .vjs-fluid {
    padding: 0;
  }
}

.wall {
  max-width: 1200px !important;
  margin: auto;
  &__carousel {
    &__name {
      color: $green;
    }
    &__info {
      display: flex;
      background-color: $white-smoke;
      padding: 50px;
      grid-auto-flow: column;
      width: 60%;
      margin: auto;
      grid-column-gap: 20px;
      border-radius: 30px;
      @include respond-between(md, lg) {
        width: 70%;
      }
      @include respond-below(sm) {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    &__avatar {
      width: 90px;
      height: 90px;
      background-color: $white;
      border-radius: 50%;
      object-fit: cover;
      @include respond-below(sm) {
        margin-bottom: 20px;
      }
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 40%;
      bottom: 0;
      z-index: 1;
      width: 62px;
      height: 62px;
      background: $orange;
      border-radius: 50%;
      box-shadow: 0 0 0px 4px rgba(250, 117, 78, 0.2);
      border: none;

      &--left {
        left: 100px;
      }
      &--right {
        right: 100px;
      }
      @include respond-below(sm) {
        display: none;
        &.--mobile {
          margin-right: 15px;
          display: flex;
          position: relative;
          height: 39px;
          width: 39px;
          box-shadow: 0 0 0px 2px rgba(250, 117, 78, 0.2);
        }
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          width: 17px;
          margin-top: 2px;
          position: absolute;
          top: 0px;
        }
      }
    }
  }
}

.play-icon {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 120px;
  height: 120px;
  background: $orange;
  border-radius: 50%;
  box-shadow: 0 0 0px 20px rgba(250, 117, 78, 0.2);

  &__triangle {
    background-image: url('~homepage/play-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 36px;
    height: 36px;
  }
  @include respond-between(md, lg) {
    width: 100px;
    height: 100px;
    box-shadow: 0 0 0px 15px rgba(250, 117, 78, 0.2);
    &__triangle {
      width: 24px;
      height: 24px;
    }
  }
  @include respond-between(xs, md) {
    width: 80px;
    height: 80px;
    box-shadow: 0 0 0px 10px rgba(250, 117, 78, 0.2);
    &__triangle {
      width: 18px;
      height: 18px;
    }
  }
  @include respond-below(xs) {
    width: 48px;
    height: 48px;
    box-shadow: 0 0 0px 5px rgba(250, 117, 78, 0.2);
    &__triangle {
      width: 10px;
      height: 10px;
    }
  }
}

.heart-icon {
  width: 33px;
  background-size: cover;
  height: 20px;
  position: absolute;
  background-image: url('~homepage/heart-icon.svg');
}

.btns-social {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
  .btn.-custom-width {
    width: 100%;
    max-width: 320px;
    height: 50px;
    margin-bottom: 15px;
  }
}

.section-reasons {
  background-color: $white-smoke;
  padding-top: 120px;
  padding-bottom: 120px;

  &.-bg-white {
    background-color: $white;
  }

  &__title {
    font-family: $font-family-title;
    font-weight: bold;
    margin: 0 7px 15px;
  }

  &__container {
    max-width: 675px;
    margin: auto;
  }

  &__bg--display {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    height: 240px;
    padding-left: 30px;
    padding-right: 30px;
    @each $name, $url in $imgs-reason {
      &.-#{$name} {
        background-image: $url;
        @if $name == 'reason-1' {
          border-top-left-radius: 30px;
          @include respond-below(sm) {
            border-top-right-radius: 30px;
          }
        }
        @if $name == 'reason-2' {
          @include respond-between(sm, md) {
            border-top-right-radius: 30px;
          }
        }
        @if $name == 'reason-3' {
          @include respond-above(md) {
            border-top-right-radius: 30px;
          }
        }
        @if $name == 'reason-4' {
          @include respond-above(md) {
            border-bottom-left-radius: 30px;
          }
        }
        @if $name == 'reason-5' {
          @include respond-between(sm, md) {
            border-bottom-left-radius: 30px;
          }
        }
        @if $name == 'reason-6' {
          border-bottom-right-radius: 30px;
          @include respond-below(sm) {
            border-bottom-left-radius: 30px;
          }
        }
      }
    }
  }

  @include respond-below(md) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .carousel {
    .course-card {
      border-radius: 10px;
      &:hover {
        border: 1px solid #f56040;
      }
      .course-image {
        width: -webkit-fill-available;
        height: 200px;
      }
    }
    .carousel-indicators {
      margin: unset !important;
    }
  }

  .checkout-course-content {
    background-image: url('~homepage/reason-3.png');
  }
}

.section-plan {
  .card {
    border-top-left-radius: 11px !important;
    border-top-right-radius: 11px !important;
    max-width: 370px;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    background-color: $white-smoke !important;
    margin: auto;
    margin-bottom: 20px;
  }
  .btn-subscribe {
    padding: 16px 62px 16px 63px;
  }

  &:nth-child(1) {
    .card {
      border-top: 20px solid $green;

      .btn-subscribe {
        background-color: $green;
        border-color: $green;
        color: $white !important;
        text-decoration: none;
      }

      .text-discount {
        color: $green;
      }
    }
  }

  &:nth-child(2) {
    .card {
      border-top: 20px solid $blue;

      .btn-subscribe {
        background-color: $blue;
        border-color: $blue;
        color: $white !important;
        text-decoration: none;
      }

      .text-discount {
        color: $blue;
      }
    }
  }

  &:nth-child(3) {
    .card {
      border-top: 20px solid $primary;

      .btn-subscribe {
        background-color: $primary;
        border-color: $primary;
        color: $white !important;
        text-decoration: none;
      }

      .text-discount {
        color: $primary;
      }
    }
  }

  &__price {
    min-height: 110px;
    padding-bottom: 10px;
    padding-top: 20px;
  }

  &__description {
    p {
      display: inline !important;
    }
  }
}

.section-contact {
  background-image: url('~homepage/bg-contact.png');
  background-size: cover;
  padding-bottom: 180px;
  padding-top: 180px;
  color: $white;
  text-align: center;
  border-radius: 30px;
  &__container {
    max-width: 570px;
    margin: auto;
    @include respond-below(xs) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  &__title {
    font-family: $font-family-title;
    font-weight: bold;
    padding-bottom: 20px;
  }
  &__btn {
    padding: 15px 50px !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 4px 6px rgb(250 117 78 / 40%);
  }
  @include respond-below(sm) {
    padding-bottom: 110px;
    padding-top: 110px;
  }
}

.section-try-free-courses {
  .title {
    font-family: $font-family-title;
    font-weight: bold;
  }

  .carousel {
    border-radius: 15px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }

  .carousel-inner {
    border-radius: 15px;
  }

  .carousel-item {
    object-fit: contain;
    height: 350px;
  }

  .image-course {
    object-fit: contain;
  }

  .carousel-caption {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 80%
    );
    width: 100%;
    bottom: 0.01rem;
    left: unset;
    right: unset;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.footer {
  font-size: 16px;
  padding-top: 60px;
  margin-top: 100px;
  background-color: $white-smoke;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  @include respond-below(sm) {
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
    font-size: 16px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }

  &__link {
    color: $gray55;
    margin-bottom: 15px;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 24px;
    line-height: 24px;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  &__socials {
    justify-content: space-between;
    align-items: center;
    display: flex;
    color: $gray55;
    padding-bottom: 30px;
    padding-top: 40px;
    &__group {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 20px;
    }
    &__icon {
      width: 50px;
      height: 50px;
      background-color: $orange;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.__facebook {
        background-color: #1e73be;
      }
      &.__tiktok {
        background-color: black;
      }
      &.__instagram {
        background-color: #f56040;
      }
    }
    @include respond-below(sm) {
      padding-bottom: 20px;
      padding-top: 30px;
      &__icon {
        width: 30px;
        height: 30px;
        &--mobile {
          font-size: 0.875em !important;
        }
      }
    }
  }
  &__btn {
    padding: 10px 30px !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 4px 6px rgb(250 117 78 / 40%);
  }
  a {
    text-decoration: none;
  }
}

.font-size-body {
  font-size: $font-size-body;
}

.checkout-this-course {
  height: 250px;

  @include respond-below(md) {
    height: 340px;
  }
  .programme-suggested-img {
    width: -webkit-fill-available;
    max-height: 100%;
    object-fit: contain;
  }
}

.section-course-advertises {
  border-radius: 20px;

  @include respond-below(md) {
    margin-top: 50px;
    border: 1px solid $gray92;
    padding: 1rem;
  }

  .video-intro {
    video {
      max-height: 550px;
    }
  }

  .content {
    .title {
      font-size: 28px;
      font-weight: 800;
    }

    .description {
      text-align: justify;
      white-space: pre-line;
      font-size: 20px;
    }

    .btn-outline-primary {
      border-radius: 20px;
      padding: 10px 0 10px 0;
    }
  }

  .schedule__carousel.--indicators {
    &.--custom {
      background-color: $orange;
      &.active {
        background-color: $orange;
      }
    }
  }
}
