.cart {
  &__card {
    border-radius: 8px;
    padding: 20px;
    background-color: #f1f1f1;
    margin-bottom: 15px;
    @include respond-below(xs) {
      padding: 15px;
    }
  }
  &__item {
    display: flex;
    grid-column-gap: 15px;
    justify-content: space-between;
    &__info {
      flex: 1;
      &__remove-cart {
        border: none;
      }
    }
    &__group {
      width: 150px;
      height: 80px;
      border-radius: 3.9px;
    }
  }
  &__total {
    background-color: $medium-red;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__title {
    padding-top: 40px;
    padding-bottom: 30px;
    align-items: center;
    grid-column-gap: 15px;
    display: flex;
    @include respond-below(xs) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
  &__summary {
    padding: 30px 30px 20px 30px;
    border-radius: 20px;
    background-color: $white-smoke;
  }
}
