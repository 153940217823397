.header {
  &__wrapper {
    background: $white;
    width: 100%;

    @include respond-below(md) {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      z-index: 5;
    }
  }

  &__space {
    padding-top: 70px;
  }
  // hamburger icon button
  &__hamburger-button {
    width: 26px;
    height: 30px;
    position: relative;
    margin-right: 0.75rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    &__dash {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $black;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 6px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 15px;
      }

      &:nth-child(4) {
        top: 24px;
      }
    }
  }

  &__toggler:checked ~ &__hamburger-button > &__hamburger-button__dash {
    &:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }

  &__nav {
    &__section {
      &:first-child {
        flex-grow: 1;
      }

      &:last-child {
        flex-grow: 0;
      }
    }

    @include respond-below(md) {
      display: flex;
      flex-direction: column;
      align-items: start !important;
      font-size: 20px;
      height: 0;
      opacity: 0;
      overflow-y: hidden;
      transition: all 0.25s ease;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;

      &__section {
        width: 100%;

        &:first-child {
          flex-grow: 0;
        }

        &:last-child {
          flex-grow: 1;
        }

        &__user {
          width: 50%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &__toggler:checked ~ &__nav {
    @include respond-below(md) {
      height: 100vh;
      opacity: 1;
      padding-bottom: 70px;
    }
  }
}
