.programme {
  &__flag {
    top: -20px;
    border-radius: 19px 0;
  }

  &__title {
    text-align: center;
    font-family: $font-family-title;
    font-weight: bold;
    padding-top: 30px;
  }
  &__input {
    &.--custom {
      background-color: $white-smoke;
      border-color: $gray92;
      border-radius: 10px;
      padding-bottom: 14px;
      padding-top: 14px;
      @include respond-below(sm) {
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 14px;
      }
      &:focus {
        background-color: $white-smoke;
        outline: none;
        border-color: $gray92;
        box-shadow: none;
      }
    }
  }

  &__button {
    padding: 9px 15px;
    border-radius: 8px;
    border: solid 1px $black;
    color: $black;
    background-color: $white;
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    &.--active {
      background-color: $golden;
    }
    &.--custom {
      padding-top: 14px;
      padding-bottom: 14px;
      border-radius: 10px;
      @include respond-below(md) {
        padding-top: 11px;
        padding-bottom: 11px;
      }
      &.--custom-color {
        background-color: #5cbccc;
        border-color: #5cbccc;
        &:hover {
          transform: translate(-0.05em, -0.05em);
          box-shadow: 0.15em 0.15em #07625c;
        }
      }
    }
  }

  &__group {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
  }
  .course-detail {
    cursor: pointer;
  }
  &__card {
    height: 100%;
    border-radius: 20px;
    background-color: $white-smoke;
    padding: 20px 0;
    border: solid 1px rgb(144, 135, 135);
    box-shadow: 0.15em 0.15em #e0dddd;
    &:hover {
      border: solid 1px $orange;
      box-shadow: 0.15em 0.15em #e5a774;
      background-color: $white;
    }
    &__img {
      height: 250px;
      object-fit: contain;
    }
  }

  &__rating {
    display: flex;

    &__star {
      color: $golden;
    }
    &__blank-star {
      color: #d2d2d2;
    }
    &__half-start {
      color: $golden;
    }
    @include respond-below(sm) {
      &__star,
      &__blank-star,
      &__half-start {
        font-size: 14px;
      }
    }
  }

  &__detail {
    &--background {
      background-image: url('~courses/bg-detail.jpeg');
      color: $white;
      padding-top: 50px;
      padding-bottom: 40px;
      @include respond-below(sm) {
        background-image: unset;
        padding-top: 0px;
        padding-bottom: 0px;
        &--mobile {
          padding-top: 20px;
          padding-bottom: 50px;
          background-image: url('~courses/bg-detail.jpeg');
        }
      }
    }
    &__wrapper {
      position: relative;
      .player-wrapper {
        border: 0;
        width: 100%;
        height: 270px;
        max-width: 100%;
        border-radius: 11px;
        .play-icon {
          width: 80px;
          height: 80px;
          background-color: $white;
          box-shadow: none;
          &__triangle {
            width: 18px;
            height: 24px;
            background-image: url('~homepage/play-icon-blue.png');
          }
        }
      }

      .course-image-container {
        opacity: 0.95;
        object-fit: contain;
        background-color: white;
      }

      @include respond-below(sm) {
        margin-top: -40px;
        .player-wrapper {
          height: 170px;
          .play-icon {
            width: 44px;
            height: 44px;
            &__triangle {
              width: 10px;
              height: 13px;
            }
          }
        }
      }
    }
    &__card {
      width: 100%;
      border-radius: 20px;
      padding: 20px;
      color: $black;
      background-color: $white-smoke;
      &.--position {
        position: absolute;
      }
      @include respond-below(sm) {
        &.--position {
          position: relative;
        }
      }
    }
    &__content {
      border: solid 1px $gray92;
      border-radius: 20px;
      margin-bottom: 30px;
      background-color: $white-smoke;
      &--header {
        border-bottom: solid 1px #c5b8b8;
        padding: 13px 30px;
      }
      &--body {
        background-color: $white;
        padding: 20px 30px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
      &--time {
        color: $green;
      }
      @include respond-below(sm) {
        &--header {
          padding: 11px 20px;
        }
        &--body {
          padding: 20px;
        }
      }
    }
    &__title {
      font-family: $font-family-title;
      font-weight: bold;
      font-size: 20px;
      padding-top: 20px;
      padding-bottom: 10px;
    }
    &__rating {
      color: $golden;
      font-size: 50px;
      font-weight: bold;
      &.--progress {
        height: 8px;
        width: 100%;
        background-color: #eaeaea;
      }
      &.--progress-bar {
        background-color: $green;
        border-radius: 5px;
      }
      @include respond-below(sm) {
        font-size: 30px;
        &.--progress {
          height: 6px;
        }
      }
    }

    &__review {
      &__wrapper-see-more {
        margin-left: 70px;
      }
      &__see-more {
        background: none;
        border: none;
      }
      &__wrapper-profile {
        display: flex;
        width: 90%;
      }
      &__avatar {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        object-fit: cover;
      }
      &__profile {
        flex: 1;
        padding-left: 20px;
      }
    }

    &__course {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      &__image {
        width: 110px;
        height: 60px;
        margin-top: 5px;
      }
      &__rating {
        color: #ffc866;
        font-weight: bold;
        margin-right: 35px;
        display: flex;
      }
      &__description {
        max-width: 270px;
      }
      @include respond-below(sm) {
        padding-bottom: 6px;
      }
    }

    &__wrapper-write-review {
      width: 100%;
      .wrapper-show-review-modal {
        .wrapper-rating-star.modal-dialog {
          margin-top: 100px;

          .custom-review-content {
            padding: 20px;
          }

          .custom-review-header {
            .custom-review-title {
              font-weight: 800;
              color: $green;
            }

            .custom-cancel-btn {
              border: 1px solid $green;
              border-radius: 4px;
              width: 30px;
            }
          }

          .custom-review-body {
            .wrapper-rating {
              margin-bottom: 35px;

              .rating-title {
                font-weight: bold;
              }
            }
          }
          .custom-review-form {
            display: flex;
            justify-content: center;
          }

          .fas {
            font-size: 25px;
          }
        }
      }
    }
    .color-primary {
      color: $orange;
    }
  }

  &__filter {
    &__category {
      padding-right: 40px;
      display: flex;
      cursor: pointer;
      align-items: center;
      @include respond-below(sm) {
        padding-right: 20px;
      }
    }
    &__options {
      color: $white;
      background-color: $green;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 20px;
      display: none;
    }
    &__remove-icon {
      font-size: 14px;
      margin-top: 4px;
      cursor: pointer;
      margin-right: 20px;
    }
    &__icon {
      font-size: 14px;
      @include respond-below(sm) {
        font-size: 12px;
      }
      transform: scaleX(-1);
    }
  }

  &__content {
    padding-top: 30px;
    @include respond-below(sm) {
      padding-top: 20px;
    }
  }

  &__desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 50px;
    line-height: 24px;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 24px;
    line-height: 24px;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
    font-size: 22px;
  }
  &__suggested {
    &__img {
      height: 150px;
    }
    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      height: 48px;
      line-height: 24px;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.img_course_index {
  object-fit: unset;
}

.--suggest_card {
  box-shadow: unset;
  &::hover {
    box-shadow: none;
  }
}
