@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/paymentfont/1.1.2/css/paymentfont.min.css");
:root {
  --bs-body-font-size: 16px !important;
  --bs-body-font-family: "Nunito" !important;
  --bs-body-color: #304c5f !important;
  --bs-body-font-weight: 300 !important;
}

.form-group label {
  margin-bottom: 6px;
  font-weight: bold;
}
.form-group label span[title=required] {
  color: #fa6464;
}
.form-group .form-control {
  padding: 15px 20px 15px 20px;
  border-radius: 10px;
  border: solid 1px #f0f0f0;
}
.form-group .form-control:focus {
  border: solid 1px #f0f0f0;
  box-shadow: none;
}
.form-group .form-control::placeholder {
  color: #b8b8b8;
  opacity: 1;
  font-size: 16px;
}

input {
  font-family: "Nunito" !important;
}

.field-custom .field {
  padding: 12px 20px;
  font-weight: 600;
  outline: none;
  margin: 10px 0 5px 0;
  border-radius: 10px;
  border: solid 1px #304c5f;
  cursor: text;
  box-shadow: 5px 5px #FFFFFF, 5px 5px 0px 1px #304c5f;
}
.field-custom .field:focus {
  border: solid 1px #304c5f;
  box-shadow: 5px 5px #FFFFFF, 5px 5px 0px 1px #304c5f;
}
@media (max-width: 767px) {
  .field-custom .field.--mobile {
    padding: 12px 0px;
  }
}
.field-custom .field.intl-tel-input input#contact_info_phone_number {
  width: 100%;
  border: none;
  color: #304c5f;
  font-weight: 600;
}
.field-custom .field.intl-tel-input input#contact_info_phone_number:focus,
.field-custom .field.intl-tel-input input#contact_info_phone_number textarea:focus,
.field-custom .field.intl-tel-input input#contact_info_phone_number select:focus {
  outline: none;
}
.field-custom .field .iti__selected-flag {
  border-radius: 6px 0px 0px 6px;
  background-color: #FFFFFF;
}
.field-custom .field::-webkit-input-placeholder {
  color: #bcbcbc;
  font-weight: 300;
}
.field-custom .field::-moz-placeholder {
  color: #bcbcbc;
  font-weight: 300;
}
.field-custom .field__radio--checked {
  background-color: #fff7e8;
}
.field-custom .field__radio__check {
  margin-right: 15px;
}
.field-custom .field__radio__check:after {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -4px;
  left: 0;
  position: relative;
  background-color: #FFFFFF;
  content: "";
  display: inline-block;
  visibility: visible;
  border: solid 1px #304c5f;
}
.field-custom .field__radio__check:checked:after {
  background-color: #8ef882;
}
.field-custom .field__radio__label {
  font-weight: bold;
  border: solid 1px #304c5f;
  padding: 13px 30px 13px 20px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px #FFFFFF, 5px 5px 0px 1px #304c5f;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.field-custom .field__icon {
  position: absolute;
  top: 27px;
  right: 30px;
}
.field-custom .field.placeholder {
  opacity: unset;
}
.field-custom .invalid-feedback {
  padding-top: 5px;
}
.field-custom [id*=otp] {
  text-align: center;
}

.form-control.is-invalid {
  box-shadow: 5px 5px #ffffff, 5px 5px 0px 1px #dc3545;
}

select.placeholder {
  color: gray;
  background-color: white;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text-grey {
  color: #838383;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.border-radius-30 {
  border-radius: 30px !important;
}

.text-light-green {
  color: #43d988;
}

.text-golden {
  color: #ffc866;
}

.text-red {
  color: #fa6464;
}

.fs-small {
  font-size: 14px !important;
}

.fs-normal {
  font-size: 16px !important;
}

.flex-1 {
  flex: 1;
}

.btn-shadow {
  color: #304c5f;
  min-width: 200px;
  height: 54px;
  border-radius: 10px;
  font-size: 16px;
  border: solid 1px #304c5f;
  transition: all ease 0.2s;
}
.btn-shadow--golden {
  background-color: #ffc866;
  box-shadow: 5px 5px #0085c3, 5px 5px 0px 1px #304c5f;
}
.btn-shadow--golden.active, .btn-shadow--golden.loading {
  background-color: #088a8b;
  color: #FFFFFF;
}
.btn-shadow--golden.active {
  box-shadow: 2px 2px #0085c3, 2px 2px 0px 1px #304c5f;
}
.btn-shadow--gray {
  background-color: #f1f1f1;
  box-shadow: 5px 5px #acacac, 5px 5px 0px 1px #304c5f;
}
.btn-shadow--gray.active {
  background-color: #bcbcbc;
  box-shadow: 2px 2px #acacac, 2px 2px 0px 1px #304c5f;
}
.btn-shadow--red {
  background-color: #fa6464;
  color: #FFFFFF;
  box-shadow: 5px 5px #acacac, 5px 5px 0px 1px #304c5f;
}
.btn-shadow--red.active {
  background-color: #dc3545;
  box-shadow: 2px 2px #acacac, 2px 2px 0px 1px #304c5f;
}
.btn-shadow--white {
  background-color: #FFFFFF;
  color: #304c5f;
  box-shadow: 5px 5px #0085c3, 5px 5px 0px 1px #304c5f;
}
.btn-shadow--white.active {
  background-color: #088a8b;
  color: #FFFFFF;
  box-shadow: 2px 2px #0085c3, 2px 2px 0px 1px #304c5f;
}
.btn-shadow.active {
  transform: translate(3px, 3px);
}
.btn-shadow.loading {
  pointer-events: none;
}
@media (max-width: 767px) {
  .btn-shadow {
    min-width: 150px;
    height: 44px;
  }
}

.fit-unset {
  object-fit: unset;
}

.fit-cover {
  object-fit: cover;
}

.btn-close.--custom {
  position: absolute;
  right: 16px;
  top: 28px;
}

.select2-container {
  width: 100% !important;
}

.text-blur {
  color: #606060;
}

.network {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  margin: 1.5rem 0.5rem 1.5rem;
}

.button-copy {
  border: none;
  font-size: 1.5rem;
  background-color: white;
}

.btn-role {
  min-height: 120px;
  padding: 2rem;
  width: 100%;
}
.btn-role:hover {
  background-color: #fff7e8;
}
.btn-role:hover svg {
  fill: #8ef882;
}

.selected-file {
  background: #ddeeff;
  border-radius: 0.2em;
  margin-top: 1px;
  padding: 0.2em;
}

.selected-file input[type=file] {
  display: none;
}

.icon-livestream {
  font-size: 30px;
  color: #206bc4;
}

.font-weight-700 {
  font-weight: 700;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.white-space-pre-line {
  white-space: pre-line;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.h-fit {
  height: fit-content !important;
}

.mx-n1 {
  margin: 0 -0.25rem;
}

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 24px !important;
  }

  h2, .h2 {
    font-size: 20px !important;
  }

  h4, .h4 {
    font-size: 18px !important;
  }

  h5, .h5 {
    font-size: 16px !important;
  }

  p, h6, .h6 {
    font-size: 14px !important;
  }
}
.header__wrapper {
  background: #FFFFFF;
  width: 100%;
}
@media (max-width: 991px) {
  .header__wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 5;
  }
}
.header__space {
  padding-top: 70px;
}
.header__hamburger-button {
  width: 26px;
  height: 30px;
  position: relative;
  margin-right: 0.75rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.header__hamburger-button__dash {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #304c5f;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.header__hamburger-button__dash:nth-child(1) {
  top: 6px;
}
.header__hamburger-button__dash:nth-child(2), .header__hamburger-button__dash:nth-child(3) {
  top: 15px;
}
.header__hamburger-button__dash:nth-child(4) {
  top: 24px;
}
.header__toggler:checked ~ .header__hamburger-button > .header__hamburger-button__dash:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.header__toggler:checked ~ .header__hamburger-button > .header__hamburger-button__dash:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.header__toggler:checked ~ .header__hamburger-button > .header__hamburger-button__dash:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.header__toggler:checked ~ .header__hamburger-button > .header__hamburger-button__dash:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.header__nav__section:first-child {
  flex-grow: 1;
}
.header__nav__section:last-child {
  flex-grow: 0;
}
@media (max-width: 991px) {
  .header__nav {
    display: flex;
    flex-direction: column;
    align-items: start !important;
    font-size: 20px;
    height: 0;
    opacity: 0;
    overflow-y: hidden;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
  }
  .header__nav__section {
    width: 100%;
  }
  .header__nav__section:first-child {
    flex-grow: 0;
  }
  .header__nav__section:last-child {
    flex-grow: 1;
  }
  .header__nav__section__user {
    width: 50%;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .header__toggler:checked ~ .header__nav {
    height: 100vh;
    opacity: 1;
    padding-bottom: 70px;
  }
}

.header {
  font-size: 16px;
}
.header__avatar {
  object-fit: cover;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .header__avatar {
    height: 30px;
    width: 30px;
  }
}
.header .select2-states {
  width: 110px;
}
.header .select2-selection--single {
  border: none !important;
}
.header .select2-selection--single .flag-icon {
  height: 30px;
  width: 44px;
  margin-right: 5px;
}
.header__icon-cart {
  width: 27px;
  height: 34px;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("~icon/payment/cart.svg");
}
@media (max-width: 991px) {
  .header__icon-cart {
    height: 30px;
    width: 30px;
  }
}
.header__icon-cart--custom-total {
  position: absolute;
  right: -15px;
  top: -15px;
}
@media (max-width: 991px) {
  .header__icon-cart--custom-total {
    max-height: 25px;
    max-width: 25px;
    right: -10px;
    top: -10px;
  }
}
.header .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: 0;
}

.logo {
  font-size: 32px;
  color: #fa754e;
  font-weight: 900;
}
@media (max-width: 767px) {
  .logo {
    font-size: 24px;
  }
}

.app_pagination {
  display: flex;
  justify-content: end;
  background: #FFFFFF;
  cursor: default;
}
.app_pagination a,
.app_pagination span,
.app_pagination em {
  padding: 0.5rem;
  color: #304c5f;
  border-radius: 10px;
  font-size: 16px;
  border: solid 1px #304c5f;
  background-color: #f1f1f1;
  box-shadow: 5px 5px #acacac, 5px 5px 0px 1px #304c5f;
  margin-right: 0.75rem;
}
.app_pagination .disabled {
  color: #838383;
}
.app_pagination .current {
  background-color: #ffc866;
  box-shadow: 5px 5px #0085c3, 5px 5px 0px 1px #304c5f;
}
.app_pagination a {
  text-decoration: none;
}
.app_pagination.--admin, .app_pagination.--leaderboard {
  background-color: unset;
  margin-top: 30px;
}
.app_pagination.--leaderboard {
  justify-content: center;
}
@media (max-width: 575px) {
  .app_pagination.--leaderboard.--mobile .pagination {
    display: flex;
    width: 150px;
    justify-content: space-around;
  }
}
@media (max-width: 575px) {
  .app_pagination {
    display: none;
  }
}
@media (min-width: 576px) {
  .app_pagination.--mobile {
    display: none;
  }
}
@media (max-width: 575px) {
  .app_pagination.--mobile {
    display: flex;
  }
}

.header-student {
  padding-bottom: 20px;
  padding-top: 20px;
  column-gap: 30px;
  background-color: #088a8b;
  display: flex;
  justify-content: center;
}
.header-student--active {
  margin-top: -10px;
  margin-bottom: -10px;
  border: solid 1px #304c5f;
  background-color: #ffc866;
  border-radius: 8px;
  padding: 9px 15px 9px 16px;
}
@media (max-width: 767px) {
  .header-student {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-student.--mobile {
    display: flex;
  }
}
@media (min-width: 768px) {
  .header-student.--mobile {
    display: none;
  }
}
.header-student__dropdown {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}
.header-student__dropdown__button.--menu {
  padding: 11px 15px;
  width: 100%;
  display: flex;
  border-radius: 8px;
  border: solid 1px #304c5f;
  font-weight: bold;
  background-color: #ffc866;
  justify-content: space-between;
  align-items: center;
}

.intro {
  position: relative;
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  color: #FFFFFF;
  padding-bottom: 120px;
}
.intro__title {
  padding-top: 40px;
  font-family: "Libre Baskerville";
  font-weight: bold;
  padding-bottom: 20px;
}
.intro__icon.--custom {
  background: #ffc866;
}
@media (max-width: 575px) {
  .intro {
    margin-top: 10px;
  }
  .intro__title {
    padding-top: 30px;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .intro {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .intro__title {
    padding-top: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .intro {
    margin-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .intro__title {
    padding-top: 20px;
  }
}
.intro__image {
  padding-bottom: 100px;
  left: 0;
  right: 0;
  z-index: -1;
  position: absolute;
  filter: url(#round);
}
.intro__image::before {
  content: "";
  display: block;
  background-size: cover;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
  background-image: url("~homepage/bg-intro.jpeg");
  height: 440px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .intro__image::before {
    height: 310px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .intro__image::before {
    height: 360px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .intro__image::before {
    height: 370px;
  }
}
@media (max-width: 575px) {
  .intro__image::before {
    height: 340px;
  }
}
.intro__video {
  padding-right: 20px;
  padding-left: 20px;
  margin-top: -125px;
}
.intro .wrapper {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
}
.intro .cta {
  display: flex;
  padding: 10px 40px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 31px;
  font-weight: 500;
  color: white;
  background: #6225e6;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
  margin-top: 2rem;
}
.intro .cta:focus {
  outline: none;
}
.intro .cta:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #fbc638;
  padding: 10px 45px;
  font-size: 35px;
}

.player-wrapper {
  position: relative;
  margin: auto;
  height: 460px;
  max-width: 860px;
  border: 20px solid #304c5f;
  border-radius: 30px;
  background-color: black;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .player-wrapper {
    height: 360px;
    width: 100%;
    border: 10px solid #304c5f;
    border-radius: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .player-wrapper {
    height: 300px;
    width: 100%;
    border: 10px solid #304c5f;
    border-radius: 10px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .player-wrapper {
    height: 340px;
    max-width: 100%;
    border: 10px solid #304c5f;
    border-radius: 10px;
  }
}
@media (max-width: 575px) {
  .player-wrapper {
    height: 180px;
    max-width: 305px;
    margin: auto;
    border: 10px solid #304c5f;
    border-radius: 10px;
  }
}
.player-wrapper.--footer-video::before {
  content: "";
  position: absolute;
  left: 25%;
  bottom: -40px;
  border-bottom: 20px solid #ffc866;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  width: 50%;
}
@media (max-width: 1199px) {
  .player-wrapper.--footer-video::before {
    bottom: -20px;
    border-bottom: 10px solid #ffc866;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.player-wrapper .vjs-tech {
  border-radius: 10px;
}
.player-wrapper .vjs-control-bar {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.player-wrapper .vjs-poster {
  background-size: cover;
  border-radius: 10px;
}
.player-wrapper .vjs-big-play-button {
  display: none !important;
}
.player-wrapper .vjs-fluid {
  padding: 0;
}

.wall {
  max-width: 1200px !important;
  margin: auto;
}
.wall__carousel__name {
  color: #088a8b;
}
.wall__carousel__info {
  display: flex;
  background-color: #f9f9f9;
  padding: 50px;
  grid-auto-flow: column;
  width: 60%;
  margin: auto;
  grid-column-gap: 20px;
  border-radius: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .wall__carousel__info {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .wall__carousel__info {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.wall__carousel__avatar {
  width: 90px;
  height: 90px;
  background-color: #FFFFFF;
  border-radius: 50%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .wall__carousel__avatar {
    margin-bottom: 20px;
  }
}
.wall__carousel__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  bottom: 0;
  z-index: 1;
  width: 62px;
  height: 62px;
  background: #fa744e;
  border-radius: 50%;
  box-shadow: 0 0 0px 4px rgba(250, 117, 78, 0.2);
  border: none;
}
.wall__carousel__icon--left {
  left: 100px;
}
.wall__carousel__icon--right {
  right: 100px;
}
@media (max-width: 767px) {
  .wall__carousel__icon {
    display: none;
  }
  .wall__carousel__icon.--mobile {
    margin-right: 15px;
    display: flex;
    position: relative;
    height: 39px;
    width: 39px;
    box-shadow: 0 0 0px 2px rgba(250, 117, 78, 0.2);
  }
  .wall__carousel__icon .carousel-control-prev-icon,
.wall__carousel__icon .carousel-control-next-icon {
    width: 17px;
    margin-top: 2px;
    position: absolute;
    top: 0px;
  }
}

.play-icon {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 120px;
  height: 120px;
  background: #fa744e;
  border-radius: 50%;
  box-shadow: 0 0 0px 20px rgba(250, 117, 78, 0.2);
}
.play-icon__triangle {
  background-image: url("~homepage/play-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .play-icon {
    width: 100px;
    height: 100px;
    box-shadow: 0 0 0px 15px rgba(250, 117, 78, 0.2);
  }
  .play-icon__triangle {
    width: 24px;
    height: 24px;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .play-icon {
    width: 80px;
    height: 80px;
    box-shadow: 0 0 0px 10px rgba(250, 117, 78, 0.2);
  }
  .play-icon__triangle {
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 575px) {
  .play-icon {
    width: 48px;
    height: 48px;
    box-shadow: 0 0 0px 5px rgba(250, 117, 78, 0.2);
  }
  .play-icon__triangle {
    width: 10px;
    height: 10px;
  }
}

.heart-icon {
  width: 33px;
  background-size: cover;
  height: 20px;
  position: absolute;
  background-image: url("~homepage/heart-icon.svg");
}

.btns-social {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}
.btns-social .btn.-custom-width {
  width: 100%;
  max-width: 320px;
  height: 50px;
  margin-bottom: 15px;
}

.section-reasons {
  background-color: #f9f9f9;
  padding-top: 120px;
  padding-bottom: 120px;
}
.section-reasons.-bg-white {
  background-color: #FFFFFF;
}
.section-reasons__title {
  font-family: "Libre Baskerville";
  font-weight: bold;
  margin: 0 7px 15px;
}
.section-reasons__container {
  max-width: 675px;
  margin: auto;
}
.section-reasons__bg--display {
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  height: 240px;
  padding-left: 30px;
  padding-right: 30px;
}
.section-reasons__bg--display.-reason-1 {
  background-image: url("~homepage/reason-4.png");
  border-top-left-radius: 30px;
}
@media (max-width: 767px) {
  .section-reasons__bg--display.-reason-1 {
    border-top-right-radius: 30px;
  }
}
.section-reasons__bg--display.-reason-2 {
  background-image: url("~homepage/reason-1.png");
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-reasons__bg--display.-reason-2 {
    border-top-right-radius: 30px;
  }
}
.section-reasons__bg--display.-reason-3 {
  background-image: url("~homepage/reason-6.png");
}
@media (min-width: 992px) {
  .section-reasons__bg--display.-reason-3 {
    border-top-right-radius: 30px;
  }
}
.section-reasons__bg--display.-reason-4 {
  background-image: url("~homepage/reason-5.png");
}
@media (min-width: 992px) {
  .section-reasons__bg--display.-reason-4 {
    border-bottom-left-radius: 30px;
  }
}
.section-reasons__bg--display.-reason-5 {
  background-image: url("~homepage/reason-2.png");
}
@media (min-width: 768px) and (max-width: 991px) {
  .section-reasons__bg--display.-reason-5 {
    border-bottom-left-radius: 30px;
  }
}
.section-reasons__bg--display.-reason-6 {
  background-image: url("~homepage/reason-3.png");
  border-bottom-right-radius: 30px;
}
@media (max-width: 767px) {
  .section-reasons__bg--display.-reason-6 {
    border-bottom-left-radius: 30px;
  }
}
@media (max-width: 991px) {
  .section-reasons {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.section-reasons .carousel .course-card {
  border-radius: 10px;
}
.section-reasons .carousel .course-card:hover {
  border: 1px solid #f56040;
}
.section-reasons .carousel .course-card .course-image {
  width: -webkit-fill-available;
  height: 200px;
}
.section-reasons .carousel .carousel-indicators {
  margin: unset !important;
}
.section-reasons .checkout-course-content {
  background-image: url("~homepage/reason-3.png");
}

.section-plan .card {
  border-top-left-radius: 11px !important;
  border-top-right-radius: 11px !important;
  max-width: 370px;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  background-color: #f9f9f9 !important;
  margin: auto;
  margin-bottom: 20px;
}
.section-plan .btn-subscribe {
  padding: 16px 62px 16px 63px;
}
.section-plan:nth-child(1) .card {
  border-top: 20px solid #088a8b;
}
.section-plan:nth-child(1) .card .btn-subscribe {
  background-color: #088a8b;
  border-color: #088a8b;
  color: #FFFFFF !important;
  text-decoration: none;
}
.section-plan:nth-child(1) .card .text-discount {
  color: #088a8b;
}
.section-plan:nth-child(2) .card {
  border-top: 20px solid #0085c3;
}
.section-plan:nth-child(2) .card .btn-subscribe {
  background-color: #0085c3;
  border-color: #0085c3;
  color: #FFFFFF !important;
  text-decoration: none;
}
.section-plan:nth-child(2) .card .text-discount {
  color: #0085c3;
}
.section-plan:nth-child(3) .card {
  border-top: 20px solid #fa754e;
}
.section-plan:nth-child(3) .card .btn-subscribe {
  background-color: #fa754e;
  border-color: #fa754e;
  color: #FFFFFF !important;
  text-decoration: none;
}
.section-plan:nth-child(3) .card .text-discount {
  color: #fa754e;
}
.section-plan__price {
  min-height: 110px;
  padding-bottom: 10px;
  padding-top: 20px;
}
.section-plan__description p {
  display: inline !important;
}

.section-contact {
  background-image: url("~homepage/bg-contact.png");
  background-size: cover;
  padding-bottom: 180px;
  padding-top: 180px;
  color: #FFFFFF;
  text-align: center;
  border-radius: 30px;
}
.section-contact__container {
  max-width: 570px;
  margin: auto;
}
@media (max-width: 575px) {
  .section-contact__container {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.section-contact__title {
  font-family: "Libre Baskerville";
  font-weight: bold;
  padding-bottom: 20px;
}
.section-contact__btn {
  padding: 15px 50px !important;
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 6px rgba(250, 117, 78, 0.4);
}
@media (max-width: 767px) {
  .section-contact {
    padding-bottom: 110px;
    padding-top: 110px;
  }
}

.section-try-free-courses .title {
  font-family: "Libre Baskerville";
  font-weight: bold;
}
.section-try-free-courses .carousel {
  border-radius: 15px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.section-try-free-courses .carousel-inner {
  border-radius: 15px;
}
.section-try-free-courses .carousel-item {
  object-fit: contain;
  height: 350px;
}
.section-try-free-courses .image-course {
  object-fit: contain;
}
.section-try-free-courses .carousel-caption {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 80%);
  width: 100%;
  bottom: 0.01rem;
  left: unset;
  right: unset;
}
.section-try-free-courses .description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.footer {
  font-size: 16px;
  padding-top: 60px;
  margin-top: 100px;
  background-color: #f9f9f9;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
}
@media (max-width: 767px) {
  .footer {
    padding-top: 60px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
    font-size: 16px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }
}
.footer__link {
  color: #555;
  margin-bottom: 15px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  line-height: 24px;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.footer__socials {
  justify-content: space-between;
  align-items: center;
  display: flex;
  color: #555;
  padding-bottom: 30px;
  padding-top: 40px;
}
.footer__socials__group {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
}
.footer__socials__icon {
  width: 50px;
  height: 50px;
  background-color: #fa744e;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__socials__icon.__facebook {
  background-color: #1e73be;
}
.footer__socials__icon.__tiktok {
  background-color: black;
}
.footer__socials__icon.__instagram {
  background-color: #f56040;
}
@media (max-width: 767px) {
  .footer__socials {
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .footer__socials__icon {
    width: 30px;
    height: 30px;
  }
  .footer__socials__icon--mobile {
    font-size: 0.875em !important;
  }
}
.footer__btn {
  padding: 10px 30px !important;
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 6px rgba(250, 117, 78, 0.4);
}
.footer a {
  text-decoration: none;
}

.font-size-body {
  font-size: 18px;
}

.checkout-this-course {
  height: 250px;
}
@media (max-width: 991px) {
  .checkout-this-course {
    height: 340px;
  }
}
.checkout-this-course .programme-suggested-img {
  width: -webkit-fill-available;
  max-height: 100%;
  object-fit: contain;
}

.section-course-advertises {
  border-radius: 20px;
}
@media (max-width: 991px) {
  .section-course-advertises {
    margin-top: 50px;
    border: 1px solid #ebebeb;
    padding: 1rem;
  }
}
.section-course-advertises .video-intro video {
  max-height: 550px;
}
.section-course-advertises .content .title {
  font-size: 28px;
  font-weight: 800;
}
.section-course-advertises .content .description {
  text-align: justify;
  white-space: pre-line;
  font-size: 20px;
}
.section-course-advertises .content .btn-outline-primary {
  border-radius: 20px;
  padding: 10px 0 10px 0;
}
.section-course-advertises .schedule__carousel.--indicators.--custom {
  background-color: #fa744e;
}
.section-course-advertises .schedule__carousel.--indicators.--custom.active {
  background-color: #fa744e;
}

.about__image {
  padding-bottom: 100px;
  display: inline-grid;
  width: 100%;
  filter: url(#round);
}
@media (max-width: 767px) {
  .about__image {
    padding-bottom: 60px;
  }
}
.about__image::before {
  content: "";
  display: block;
  background-size: cover;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0% 100%, 0% 100%);
  background-image: url("~homepage/bg-about.jpeg");
  height: 540px;
}
@media (max-width: 991px) {
  .about__image::before {
    height: 480px;
  }
}
@media (max-width: 767px) {
  .about__image::before {
    height: 410px;
    background-position: 30%;
  }
}
.about__title {
  text-align: center;
  font-family: "Libre Baskerville";
  font-weight: bold;
  padding-bottom: 30px;
}
.about__reasons {
  padding-top: 30px;
  padding-bottom: 100px;
}
.about__reasons__description {
  padding-left: 10px;
  padding-right: 10px;
}
.about__learning {
  background-color: #f9f9f9;
  border-radius: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
}
.about__learning__description {
  max-width: 470px;
  margin: auto;
  padding-top: 30px;
  text-align: center;
}
.about__team {
  padding-top: 100px;
  padding-bottom: 84px;
}
.about__team--icon {
  color: #0085c3;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
}
.about__team__member {
  max-width: 370px !important;
}

.programme__flag {
  top: -20px;
  border-radius: 19px 0;
}
.programme__title {
  text-align: center;
  font-family: "Libre Baskerville";
  font-weight: bold;
  padding-top: 30px;
}
.programme__input.--custom {
  background-color: #f9f9f9;
  border-color: #ebebeb;
  border-radius: 10px;
  padding-bottom: 14px;
  padding-top: 14px;
}
@media (max-width: 767px) {
  .programme__input.--custom {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 14px;
  }
}
.programme__input.--custom:focus {
  background-color: #f9f9f9;
  outline: none;
  border-color: #ebebeb;
  box-shadow: none;
}
.programme__button {
  padding: 9px 15px;
  border-radius: 8px;
  border: solid 1px #304c5f;
  color: #304c5f;
  background-color: #FFFFFF;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}
.programme__button.--active {
  background-color: #ffc866;
}
.programme__button.--custom {
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .programme__button.--custom {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
.programme__button.--custom.--custom-color {
  background-color: #5cbccc;
  border-color: #5cbccc;
}
.programme__button.--custom.--custom-color:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em #07625c;
}
.programme__group {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
}
.programme .course-detail {
  cursor: pointer;
}
.programme__card {
  height: 100%;
  border-radius: 20px;
  background-color: #f9f9f9;
  padding: 20px 0;
  border: solid 1px #908787;
  box-shadow: 0.15em 0.15em #e0dddd;
}
.programme__card:hover {
  border: solid 1px #fa744e;
  box-shadow: 0.15em 0.15em #e5a774;
  background-color: #FFFFFF;
}
.programme__card__img {
  height: 250px;
  object-fit: contain;
}
.programme__rating {
  display: flex;
}
.programme__rating__star {
  color: #ffc866;
}
.programme__rating__blank-star {
  color: #d2d2d2;
}
.programme__rating__half-start {
  color: #ffc866;
}
@media (max-width: 767px) {
  .programme__rating__star, .programme__rating__blank-star, .programme__rating__half-start {
    font-size: 14px;
  }
}
.programme__detail--background {
  background-image: url("~courses/bg-detail.jpeg");
  color: #FFFFFF;
  padding-top: 50px;
  padding-bottom: 40px;
}
@media (max-width: 767px) {
  .programme__detail--background {
    background-image: unset;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .programme__detail--background--mobile {
    padding-top: 20px;
    padding-bottom: 50px;
    background-image: url("~courses/bg-detail.jpeg");
  }
}
.programme__detail__wrapper {
  position: relative;
}
.programme__detail__wrapper .player-wrapper {
  border: 0;
  width: 100%;
  height: 270px;
  max-width: 100%;
  border-radius: 11px;
}
.programme__detail__wrapper .player-wrapper .play-icon {
  width: 80px;
  height: 80px;
  background-color: #FFFFFF;
  box-shadow: none;
}
.programme__detail__wrapper .player-wrapper .play-icon__triangle {
  width: 18px;
  height: 24px;
  background-image: url("~homepage/play-icon-blue.png");
}
.programme__detail__wrapper .course-image-container {
  opacity: 0.95;
  object-fit: contain;
  background-color: white;
}
@media (max-width: 767px) {
  .programme__detail__wrapper {
    margin-top: -40px;
  }
  .programme__detail__wrapper .player-wrapper {
    height: 170px;
  }
  .programme__detail__wrapper .player-wrapper .play-icon {
    width: 44px;
    height: 44px;
  }
  .programme__detail__wrapper .player-wrapper .play-icon__triangle {
    width: 10px;
    height: 13px;
  }
}
.programme__detail__card {
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  color: #304c5f;
  background-color: #f9f9f9;
}
.programme__detail__card.--position {
  position: absolute;
}
@media (max-width: 767px) {
  .programme__detail__card.--position {
    position: relative;
  }
}
.programme__detail__content {
  border: solid 1px #ebebeb;
  border-radius: 20px;
  margin-bottom: 30px;
  background-color: #f9f9f9;
}
.programme__detail__content--header {
  border-bottom: solid 1px #c5b8b8;
  padding: 13px 30px;
}
.programme__detail__content--body {
  background-color: #FFFFFF;
  padding: 20px 30px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.programme__detail__content--time {
  color: #088a8b;
}
@media (max-width: 767px) {
  .programme__detail__content--header {
    padding: 11px 20px;
  }
  .programme__detail__content--body {
    padding: 20px;
  }
}
.programme__detail__title {
  font-family: "Libre Baskerville";
  font-weight: bold;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
}
.programme__detail__rating {
  color: #ffc866;
  font-size: 50px;
  font-weight: bold;
}
.programme__detail__rating.--progress {
  height: 8px;
  width: 100%;
  background-color: #eaeaea;
}
.programme__detail__rating.--progress-bar {
  background-color: #088a8b;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .programme__detail__rating {
    font-size: 30px;
  }
  .programme__detail__rating.--progress {
    height: 6px;
  }
}
.programme__detail__review__wrapper-see-more {
  margin-left: 70px;
}
.programme__detail__review__see-more {
  background: none;
  border: none;
}
.programme__detail__review__wrapper-profile {
  display: flex;
  width: 90%;
}
.programme__detail__review__avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  object-fit: cover;
}
.programme__detail__review__profile {
  flex: 1;
  padding-left: 20px;
}
.programme__detail__course {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.programme__detail__course__image {
  width: 110px;
  height: 60px;
  margin-top: 5px;
}
.programme__detail__course__rating {
  color: #ffc866;
  font-weight: bold;
  margin-right: 35px;
  display: flex;
}
.programme__detail__course__description {
  max-width: 270px;
}
@media (max-width: 767px) {
  .programme__detail__course {
    padding-bottom: 6px;
  }
}
.programme__detail__wrapper-write-review {
  width: 100%;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog {
  margin-top: 100px;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog .custom-review-content {
  padding: 20px;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog .custom-review-header .custom-review-title {
  font-weight: 800;
  color: #088a8b;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog .custom-review-header .custom-cancel-btn {
  border: 1px solid #088a8b;
  border-radius: 4px;
  width: 30px;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog .custom-review-body .wrapper-rating {
  margin-bottom: 35px;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog .custom-review-body .wrapper-rating .rating-title {
  font-weight: bold;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog .custom-review-form {
  display: flex;
  justify-content: center;
}
.programme__detail__wrapper-write-review .wrapper-show-review-modal .wrapper-rating-star.modal-dialog .fas {
  font-size: 25px;
}
.programme__detail .color-primary {
  color: #fa744e;
}
.programme__filter__category {
  padding-right: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
}
@media (max-width: 767px) {
  .programme__filter__category {
    padding-right: 20px;
  }
}
.programme__filter__options {
  color: #FFFFFF;
  background-color: #088a8b;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: none;
}
.programme__filter__remove-icon {
  font-size: 14px;
  margin-top: 4px;
  cursor: pointer;
  margin-right: 20px;
}
.programme__filter__icon {
  font-size: 14px;
  transform: scaleX(-1);
}
@media (max-width: 767px) {
  .programme__filter__icon {
    font-size: 12px;
  }
}
.programme__content {
  padding-top: 30px;
}
@media (max-width: 767px) {
  .programme__content {
    padding-top: 20px;
  }
}
.programme__desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 50px;
  line-height: 24px;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.programme__name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 24px;
  line-height: 24px;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 22px;
}
.programme__suggested__img {
  height: 150px;
}
.programme__suggested__name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 48px;
  line-height: 24px;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.img_course_index {
  object-fit: unset;
}

.--suggest_card {
  box-shadow: unset;
}
.--suggest_card::hover {
  box-shadow: none;
}

.schedule__player {
  max-height: 362px;
  border-radius: 21.3px;
  border-width: 14px;
  width: 610px;
}
@media (max-width: 767px) {
  .schedule__player {
    width: unset;
  }
}
.schedule .play-icon {
  width: 80px;
  height: 80px;
  box-shadow: 0 0 0px 10px rgba(250, 117, 78, 0.2);
}
.schedule .play-icon__triangle {
  width: 18px;
  height: 18px;
}
@media (max-width: 767px) {
  .schedule .play-icon {
    width: 40px;
    height: 40px;
    box-shadow: 0 0 0px 5px rgba(250, 117, 78, 0.2);
  }
}
.schedule .vjs-fluid {
  padding-top: unset;
}
.schedule__card {
  padding: 30px;
  border-radius: 30px;
  background-color: #f9f9f9;
}
.schedule__carousel.--indicators {
  position: unset;
  margin: 0;
}
.schedule__carousel.--indicators.--custom {
  width: 15px;
  height: 6px;
  margin: 12px 5px 31px 0px;
  border-radius: 3px;
  background-color: #ebebeb;
  border: none;
}
.schedule__carousel.--indicators.active {
  width: 50px;
  background-color: #0085c3;
}
.schedule__title {
  font-family: "Libre Baskerville";
  margin-right: 30px;
  font-weight: bold;
}
.schedule__date {
  padding: 11px 19px;
  border-radius: 15px;
  border: solid 1px #088a8b;
  color: #088a8b;
  font-weight: bold;
  width: 70px;
  text-align: center;
  height: 70px;
  margin-right: 30px;
  font-size: 18px;
}
@media (max-width: 767px) {
  .schedule__date {
    margin-bottom: 10px;
  }
}
.schedule__group {
  display: flex;
  padding-bottom: 70px;
}
@media (max-width: 767px) {
  .schedule__group {
    display: flex;
    flex-direction: column;
  }
}
.schedule__watch {
  padding: 13px 23px;
  border-radius: 10px;
  background-color: #fa754e;
  color: white;
  width: fit-content;
  display: flex;
  margin: auto;
  margin-top: -77px;
  position: relative;
  cursor: pointer;
}
.schedule .close {
  position: absolute;
  right: -50px;
  top: -1.5rem;
  z-index: 999;
  font-size: 4rem;
  font-weight: normal;
  color: black;
  border: none;
  background: unset;
  opacity: 1;
}
@media (max-width: 575px) {
  .schedule__video-modal {
    max-width: 100%;
    height: 240px;
  }
}
.schedule__modal {
  padding-top: 20vh;
}
@media (max-width: 575px) {
  .schedule__modal {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.schedule__description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 24px;
  height: 48px;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 18px;
}
.schedule .img-poster {
  height: 250px;
  filter: brightness(80%);
}

.contact-us {
  background-size: auto;
  background-repeat: no-repeat;
  background-image: url("~homepage/bg-contact-us.jpg");
  padding-top: 50px;
}
@media (max-width: 991px) {
  .contact-us {
    padding-top: 20px;
  }
}
.contact-us__title {
  font-family: "Libre Baskerville";
  font-weight: bold;
  color: #fa754e;
}
.contact-us__select.--custom {
  width: 150px;
  border-radius: 10px;
  border: solid 1px #ebebeb;
  background-color: #f9f9f9;
}
.contact-us__select.--custom:focus {
  border: solid 1px #ebebeb;
  box-shadow: none;
}
.contact-us__map {
  height: 400px;
  border-radius: 20px;
  border: solid 1px #ebebeb;
  width: 100%;
}
@media (max-width: 767px) {
  .contact-us__map {
    height: 200px;
  }
}
.contact-us__card {
  padding: 30px;
  border-radius: 20px;
  background-color: #f9f9f9;
}
@media (max-width: 991px) {
  .contact-us__card {
    margin-top: 30px;
  }
}
.contact-us .form-group label {
  height: 22px;
}

.devise-flash-container {
  max-width: 570px;
  margin: 0 auto;
}

.devise-form-container {
  margin-top: 5vh;
}
@media (max-width: 767px) {
  .devise-form-container {
    margin-top: 20px;
  }
}
.devise-form-container .logo {
  width: 210px;
}
.devise-form-container .card {
  padding: 24px;
  max-width: 570px;
}
.devise-form-container__title {
  font-size: 22px;
}
.devise-form-container .form-recaptcha {
  min-height: 78px;
}

.registration {
  padding-top: 60px;
  padding-bottom: 100px;
  background: #f9f9f9;
}
.registration__form {
  max-width: 570px;
  margin: auto;
}

.progressing {
  width: 100%;
  border: 1px dashed #304c5f;
  background-color: #f1f1f1;
  height: 20px;
  border-radius: 13.5px;
  margin-bottom: 20px;
  position: relative;
}
.progressing--wrapper {
  position: absolute;
  max-width: 570px;
  left: -2px;
  right: -2px;
  margin: auto;
}
.progressing--children, .progressing--basic {
  width: 25%;
}
.progressing--parent, .progressing--identity {
  width: 50%;
}
.progressing--2fa {
  width: 75%;
}
.progressing--payment {
  width: 100%;
}
.progressing--completed {
  border: 1px solid #304c5f;
  height: 20px;
  background-color: #ffc866;
  border-radius: 13.5px;
  margin-top: -1px;
}

.my-courses {
  padding-bottom: 40px;
}
.my-courses__empty {
  padding-top: 80px;
}
@media (max-width: 767px) {
  .my-courses__empty {
    font-size: 16px !important;
  }
}
.my-courses__card {
  border-radius: 20px;
  background-color: #f9f9f9;
  padding: 30px;
}
.my-courses__nav {
  font-size: 18px;
  border-bottom: 2px solid #ebebeb;
}
.my-courses__nav .nav-link {
  padding: 0 0 20px 0;
  margin-right: 50px;
  border: none;
  color: #304c5f;
  font-size: 18px;
}
.my-courses__nav .nav-link.active {
  border-bottom: 5px solid #088a8b;
  border-radius: 0;
  font-weight: bold;
}
.my-courses__nav .nav-link.active.--custom-color {
  color: #304c5f;
  background-color: unset;
}
@media (max-width: 575px) {
  .my-courses__nav {
    padding-top: 300px;
  }
  .my-courses__nav .nav-link {
    padding: 0 0 15px 0;
    margin-right: 15px;
    font-size: 14px;
  }
  .my-courses__nav .nav-link.active {
    border-bottom: 3px solid #088a8b;
  }
}
.my-courses .fill-blank {
  border: 1px solid black;
  background-color: unset;
  border-radius: 10px;
}
.my-courses__video {
  border-radius: 20px;
  border: 15px solid #ffc866;
  background-color: #ffc866;
}
.my-courses__video .h5p-iframe-wrapper {
  display: flex;
}
.my-courses__video .h5p-iframe-wrapper iframe.h5p-iframe {
  border-radius: 10px;
}
.my-courses__video.--loading {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-courses__video.--loading .h5p-player-component-root {
  width: 0;
}
@media (max-width: 575px) {
  .my-courses__video.--loading {
    height: 210px;
    min-height: unset;
  }
}
@media (max-width: 575px) {
  .my-courses__video {
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}
.my-courses__group-info {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px 40px;
}
@media (max-width: 991px) {
  .my-courses__group-info {
    gap: 10px 30px;
  }
}
.my-courses__carousel {
  border-radius: 20px;
  padding: 30px 45px;
  background-image: url("~courses/bg-carousel.jpeg");
}
.my-courses__carousel__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  bottom: 0;
  z-index: 1;
  width: 70px;
  height: 70px;
  background: #ffc866;
  border-radius: 50%;
  box-shadow: 0 0 0px 4px rgba(255, 200, 102, 0.2);
  border: none;
}
.my-courses__carousel__icon--left {
  left: -85px;
}
.my-courses__carousel__icon--right {
  right: -85px;
}
.my-courses__carousel__icon .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23304c5f'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.my-courses__carousel__icon .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23304c5f'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
@media (min-width: 768px) and (max-width: 991px) {
  .my-courses__carousel__icon--left {
    left: -80px;
  }
  .my-courses__carousel__icon--right {
    right: -80px;
  }
}
@media (max-width: 767px) {
  .my-courses__carousel__icon {
    height: 39px;
    width: 39px;
  }
  .my-courses__carousel__icon .carousel-control-prev-icon,
.my-courses__carousel__icon .carousel-control-next-icon {
    width: 17px;
    margin-top: 2px;
  }
  .my-courses__carousel__icon--left {
    left: -30px;
  }
  .my-courses__carousel__icon--right {
    right: -30px;
  }
}
@media (max-width: 767px) {
  .my-courses__carousel {
    padding: 20px 15px;
  }
}
.my-courses .modal .modal-dialog {
  max-width: 670px;
  max-height: 724px;
}
.my-courses .modal .modal-dialog .modal-content {
  padding: 40px 40px 44px;
  border-radius: 30px;
}
.my-courses .modal .modal-dialog .modal-content .student-info {
  display: flex;
  align-items: center;
}
.my-courses .modal .modal-dialog .modal-content .student-info .avatar {
  object-fit: cover;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 15px;
}
@media (max-width: 991px) {
  .my-courses .modal .modal-dialog .modal-content .student-info .avatar {
    height: 30px;
    width: 30px;
  }
}
.my-courses .modal .modal-dialog .modal-content .detail-info {
  margin-top: 10px;
}
.my-courses .modal .modal-dialog .modal-content .detail-info__row {
  display: flex;
  justify-content: space-between;
}
.my-courses .modal .modal-dialog .modal-content .detail-info__row .key {
  margin-right: 30px;
}
.my-courses .modal .modal-dialog .modal-content .divider {
  border-bottom: 1px solid #ebebeb;
  margin: 20px 0px;
}
.my-courses .modal .modal-dialog .modal-content .detail-result {
  height: 350px;
  overflow: scroll;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .question {
  margin-bottom: 15px;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .answers-wrapper {
  margin-bottom: 20px;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .answers-wrapper .answer-row {
  margin-bottom: 18px;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .answers-wrapper .answer-row .answer-order {
  margin-right: 15px;
  width: 30px;
  height: 30px;
  padding: 6px 9px 5px 10px;
  border: solid 0.9px #304c5f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .answers-wrapper .answer-row .answer-order.white {
  background-color: #f9f9f9;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .answers-wrapper .answer-row .answer-order.green {
  background-color: #088a8b;
  color: #f9f9f9;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .answers-wrapper .answer-row .answer-order.red {
  background-color: #fa6464;
  color: #f9f9f9;
}
.my-courses .modal .modal-dialog .modal-content .detail-result .answers-wrapper .answer-row .answer-order.black {
  background-color: #304c5f;
  color: #f9f9f9;
}
.my-courses .modal .modal-dialog .modal-content .filter {
  min-width: 140px;
  max-width: 150px;
  color: #304c5f;
  height: 34px;
  padding: 6px 13px 6px 14px;
  border-radius: 8px;
  border: solid 1px #ebebeb;
  background-color: #f9f9f9;
  margin-bottom: 15px;
}
.my-courses .modal .modal-dialog .modal-content .answer_try {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
}
.my-courses .wrapper-user-course-lesson .custom-completed-lesson {
  text-align: center;
  margin-bottom: 1.5rem;
}
.my-courses .wrapper-user-course-lesson .custom-completed-lesson .disable-button-completed {
  pointer-events: none;
  opacity: 0.4;
}
.my-courses .wrapper-user-course-lesson .custom-completed-lesson .custom-confirm-modal {
  max-width: 550px;
}

.progress--circle {
  position: relative;
  display: inline-block;
  margin: 20px 20px 20px 0;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #ebebeb;
}
.progress--circle:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: #FFFFFF;
}
.progress--circle:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff5b5b;
}
.progress__number {
  position: absolute;
  top: 40%;
  width: 100%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  font-size: 14px;
  color: #ff5b5b;
}

.progress--circle.progress--0:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(90deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--1:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(93.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--2:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(97.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--3:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(100.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--4:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(104.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--5:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(108deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--6:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(111.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--7:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(115.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--8:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(118.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--9:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(122.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--10:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(126deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--11:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(129.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--12:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(133.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--13:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(136.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--14:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(140.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--15:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(144deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--16:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(147.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--17:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(151.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--18:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(154.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--19:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(158.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--20:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(162deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--21:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(165.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--22:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(169.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--23:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(172.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--24:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(176.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--25:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(180deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--26:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(183.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--27:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(187.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--28:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(190.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--29:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(194.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--30:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(198deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--31:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(201.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--32:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(205.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--33:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(208.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--34:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(212.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--35:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(216deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--36:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(219.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--37:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(223.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--38:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(226.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--39:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(230.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--40:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(234deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--41:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(237.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--42:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(241.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--43:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(244.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--44:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(248.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--45:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(252deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--46:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(255.6deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--47:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(259.2deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--48:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(262.8deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--49:after {
  background-image: linear-gradient(90deg, #ebebeb 50%, transparent 50%, transparent), linear-gradient(266.4deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--50:after {
  background-image: linear-gradient(-90deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--51:after {
  background-image: linear-gradient(-86.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--52:after {
  background-image: linear-gradient(-82.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--53:after {
  background-image: linear-gradient(-79.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--54:after {
  background-image: linear-gradient(-75.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--55:after {
  background-image: linear-gradient(-72deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--56:after {
  background-image: linear-gradient(-68.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--57:after {
  background-image: linear-gradient(-64.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--58:after {
  background-image: linear-gradient(-61.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--59:after {
  background-image: linear-gradient(-57.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--60:after {
  background-image: linear-gradient(-54deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--61:after {
  background-image: linear-gradient(-50.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--62:after {
  background-image: linear-gradient(-46.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--63:after {
  background-image: linear-gradient(-43.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--64:after {
  background-image: linear-gradient(-39.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--65:after {
  background-image: linear-gradient(-36deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--66:after {
  background-image: linear-gradient(-32.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--67:after {
  background-image: linear-gradient(-28.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--68:after {
  background-image: linear-gradient(-25.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--69:after {
  background-image: linear-gradient(-21.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--70:after {
  background-image: linear-gradient(-18deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--71:after {
  background-image: linear-gradient(-14.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--72:after {
  background-image: linear-gradient(-10.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--73:after {
  background-image: linear-gradient(-7.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--74:after {
  background-image: linear-gradient(-3.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--75:after {
  background-image: linear-gradient(0deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--76:after {
  background-image: linear-gradient(3.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--77:after {
  background-image: linear-gradient(7.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--78:after {
  background-image: linear-gradient(10.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--79:after {
  background-image: linear-gradient(14.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--80:after {
  background-image: linear-gradient(18deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--81:after {
  background-image: linear-gradient(21.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--82:after {
  background-image: linear-gradient(25.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--83:after {
  background-image: linear-gradient(28.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--84:after {
  background-image: linear-gradient(32.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--85:after {
  background-image: linear-gradient(36deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--86:after {
  background-image: linear-gradient(39.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--87:after {
  background-image: linear-gradient(43.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--88:after {
  background-image: linear-gradient(46.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--89:after {
  background-image: linear-gradient(50.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--90:after {
  background-image: linear-gradient(54deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--91:after {
  background-image: linear-gradient(57.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--92:after {
  background-image: linear-gradient(61.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--93:after {
  background-image: linear-gradient(64.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--94:after {
  background-image: linear-gradient(68.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--95:after {
  background-image: linear-gradient(72deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--96:after {
  background-image: linear-gradient(75.6deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--97:after {
  background-image: linear-gradient(79.2deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--98:after {
  background-image: linear-gradient(82.8deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--99:after {
  background-image: linear-gradient(86.4deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.progress--circle.progress--100:after {
  background-image: linear-gradient(90deg, #ff5b5b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff5b5b 50%, #ebebeb 50%, #ebebeb);
}

.lesson__card {
  padding: 11px 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}
.lesson__card__title {
  cursor: pointer;
}
.lesson__card__item {
  display: flex;
  border-radius: 8px;
  padding: 10px 15px;
  background-color: #f1f1f1;
  margin-top: 15px;
  grid-column-gap: 15px;
  cursor: pointer;
}
@media (max-width: 575px) {
  .lesson__card__item {
    flex-direction: column;
  }
}
.lesson__card__item__content {
  font-size: 14px;
  font-weight: bold;
}
.lesson__card__item__group {
  position: relative;
  max-width: 150px;
  max-height: 80px;
}
@media (max-width: 575px) {
  .lesson__card__item__group {
    max-width: 100%;
    height: 155px;
    max-height: unset;
    margin-bottom: 10px;
  }
}
.lesson__card__item__group .play-icon {
  width: 32px;
  height: 32px;
  top: -7px;
  box-shadow: 0 0 0px 4px rgba(250, 117, 78, 0.2);
}
.lesson__card__item__group .play-icon__triangle {
  width: 7px;
  height: 10px;
}
.lesson__card__item__image {
  width: 150px;
  height: 80px;
  border-radius: 3.9px;
}
@media (max-width: 575px) {
  .lesson__card__item__image {
    width: 100%;
    height: 100%;
  }
}
.lesson__card__item__name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 24px;
  height: 48px;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 575px) {
  .lesson__card__item__name {
    height: unset;
  }
}
.lesson__card__item.--active {
  background-color: #ffc866;
}
.lesson__card__item.--active .play-icon__triangle {
  background-image: url("~homepage/pause-icon.svg");
}
.lesson__card__item:hover {
  border: solid 1px #304c5f;
  background-color: #ffc866;
  padding: 9px 14px;
}
.lesson__card:hover {
  border: solid 1px #304c5f;
  padding: 10px 14px;
}
.lesson__card .disable-lesson {
  pointer-events: none;
  cursor: default;
  display: block;
}
.lesson__result {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  padding-bottom: 5rem;
}
@media (max-width: 991px) {
  .lesson__result {
    padding-bottom: 1rem;
  }
}
.lesson__result__chart {
  width: 300px;
  height: 300px;
  position: relative;
}
@media (max-width: 991px) {
  .lesson__result__chart {
    width: 200px;
    height: 200px;
    margin-bottom: 40px;
  }
}
.lesson__result__chart__text {
  text-align: center;
  top: 45%;
  left: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.lesson__result__icon {
  width: 18px;
  height: 18px;
  margin: 0 8px 1px 0;
  border-radius: 50%;
}
.lesson__result__icon.--correct {
  background-color: #0085c3;
}
.lesson__result__icon.--wrong {
  background-color: #fa6464;
}
.lesson__result__icon.--custom {
  background-color: var(--custom-color);
}
.lesson__result__legend {
  flex-direction: column;
  display: flex;
  align-self: center;
}
@media (max-width: 991px) {
  .lesson__result {
    flex-direction: column;
    display: flex;
    align-items: center;
  }
  .lesson__result__legend {
    flex-direction: unset;
    justify-content: space-between;
    width: 100%;
  }
  .lesson__result__legend.--custom {
    flex-direction: column;
  }
}
.lesson__navigation {
  padding: 11px 15px;
  width: 100%;
  border-radius: 10px;
  background-color: #f9f9f9;
  border: none;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #304c5f;
}
@media (min-width: 992px) {
  .lesson__navigation {
    display: none;
  }
}
.lesson--no-subscription {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}

.table-statistics {
  margin-top: 1.5rem;
}
.table-statistics thead th {
  font-weight: 400 !important;
  padding: 10px 20px;
}
.table-statistics thead .name {
  max-width: 600px;
  min-width: 300px;
}
.table-statistics tbody tr {
  margin: 10px 0px;
  background-color: #f9f9f9;
  border: 10px solid #FFFFFF;
}
.table-statistics tbody tr td {
  padding: 20px;
  font-weight: 700 !important;
}
.table-statistics tbody tr td:first-child {
  border-top-left-radius: 35px !important;
  border-bottom-left-radius: 35px !important;
}
.table-statistics tbody tr td:last-child {
  border-top-right-radius: 35px !important;
  border-bottom-right-radius: 35px !important;
}

.readmore {
  margin-bottom: 30px;
  position: relative;
  max-height: 245px;
  overflow: hidden;
  width: 100%;
  padding: 30px;
  border-radius: 16px;
  background-color: #f9f9f9;
  padding-bottom: 60px;
  transition: max-height 0.15s ease-out;
}
@media (max-width: 991px) {
  .readmore {
    padding: 20px;
  }
}
.readmore.--expand {
  max-height: 100%;
  transition: max-height 0.35s ease-in-out;
  padding: 30px;
}
.readmore.--expand:after {
  height: 0;
}
.readmore.--expand .readmore__description {
  max-height: 100%;
}
.readmore__link {
  font-weight: bold;
  font-size: 16px;
  padding-top: 10px;
  position: absolute;
  bottom: 30px;
  cursor: pointer;
  margin-bottom: 0;
  z-index: 1;
  width: 100%;
  padding-top: 140px;
}
@media (max-width: 991px) {
  .readmore__link {
    padding-top: 70px;
    bottom: 20px;
  }
}
.readmore__link.--expand {
  position: relative;
  background-image: none;
  padding-top: 20px;
  bottom: 0;
}
.readmore__link.--expand:after {
  content: "Read less";
}
.readmore__link:after {
  content: "Read more";
}
.readmore:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  display: block;
  background-color: inherit;
}
@media (max-width: 991px) {
  .readmore:after {
    height: 45px;
  }
}
.readmore.no-after {
  padding-bottom: 30px;
}
.readmore.no-after:after {
  height: 0;
}
.readmore__description {
  max-height: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 24px;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
@media (max-width: 991px) {
  .readmore__description {
    max-height: 150px;
  }
}

.nav-link-course {
  text-decoration: none;
  color: #304c5f;
}
.nav-link-course:hover {
  color: #fa744e;
}

.disable-button {
  pointer-events: none;
  opacity: 0.5;
}

.livestream-collapse {
  border-radius: 10px;
  border: 1px solid black;
}

.livestream-content {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: transparent !important;
}

.my-payment {
  padding-top: 40px;
}
@media (max-width: 575px) {
  .my-payment {
    padding-top: 1.5rem;
  }
}
.my-payment__card {
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 20px;
}
.my-payment__card__empty {
  padding-top: 70px;
  padding-bottom: 70px;
  text-align: center;
}
.my-payment__credit-card {
  background-image: url("~dashboard/payment/visa-card.jpeg");
  background-size: contain;
  width: 355px;
  height: 206px;
  padding: 20px;
  border-radius: 30px;
  box-shadow: 15px 15px #f9f9f9;
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
.my-payment__credit-card[name=amex] {
  background-image: url("~dashboard/payment/ae-card.jpeg");
}
.my-payment__credit-card[name=mastercard] {
  background-image: url("~dashboard/payment/master-card.jpeg");
}
.my-payment__credit-card[name=unionpay] {
  background-image: url("~dashboard/payment/unionpay-card.jpeg");
}
.my-payment__credit-card__title {
  color: #78afbe;
  font-size: 12px;
}
.my-payment__credit-card__title[name=amex] {
  color: #93cdff;
  font-size: 12px;
}
.my-payment__credit-card__title[name=mastercard] {
  color: #7891de;
  font-size: 12px;
}
.my-payment__credit-card__title[name=unionpay] {
  color: #ffa5b2;
  font-size: 12px;
}
.my-payment__credit-card__icon-card {
  background-image: url("~icon/payment/visa.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 80px;
  height: 25px;
}
.my-payment__credit-card__icon-card[name=amex] {
  background-image: url("~icon/payment/ae-card-text.svg");
  width: 65px;
}
.my-payment__credit-card__icon-card[name=mastercard] {
  width: 137px;
  background-image: url("~icon/payment/master-card-text.svg");
}
.my-payment__credit-card__icon-card[name=unionpay] {
  height: 40px;
  background-image: url("~icon/payment/union-pay.svg");
}
.my-payment__credit-card__radio {
  height: 1.5rem !important;
  width: 3rem !important;
  cursor: pointer;
}
.my-payment__credit-card__sticky-button {
  top: 1.5rem;
}
.my-payment__icon {
  background-size: contain;
  width: 48px;
  height: 30px;
}
.my-payment__icon--visa {
  background-image: url("~icon/payment/visa-card.svg");
}
.my-payment__icon--mastercard {
  background-image: url("~icon/payment/master-card.svg");
}
.my-payment__icon--amex {
  background-image: url("~icon/payment/amex-card.svg");
}
.my-payment__icon--unionpay {
  background-image: url("~icon/payment/union-pay-card.png");
}

.dashboard {
  overflow: hidden;
}
.dashboard__card {
  border-radius: 30px;
  background-color: #f9f9f9;
  padding: 30px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .dashboard__card {
    padding: 15px;
  }
}
.dashboard__card__avatar {
  object-fit: cover;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin-top: -55px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .dashboard__card__avatar {
    width: 100px;
    height: 100px;
    margin-top: -35px;
  }
}
.dashboard__card__button.--custom {
  min-width: 170px;
  height: 46px;
}
@media (max-width: 767px) {
  .dashboard__card__button.--custom {
    min-width: 150px;
    height: 40px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .dashboard__card__subscription {
    flex-direction: column;
    row-gap: 10px;
  }
}
.dashboard__hat {
  background-image: url("~dashboard/hat.svg");
  width: 155px;
  height: 80px;
  margin-bottom: 54px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: -1;
  top: -40px;
  left: -60px;
}
@media (max-width: 767px) {
  .dashboard__hat {
    display: none;
  }
}
.dashboard__pencil {
  background-image: url("~dashboard/pencil.svg");
  width: 80px;
  height: 160px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  z-index: -1;
  bottom: 0px;
  right: -40px;
}
@media (max-width: 767px) {
  .dashboard__pencil {
    display: none;
  }
}
.dashboard__icon {
  width: 17px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.dashboard__icon.--calendar {
  background-image: url("~dashboard/calendar.svg");
}
.dashboard__icon.--level {
  margin-top: 5px;
  background-image: url("~dashboard/grade_level.svg");
  width: 24px;
  height: 16px;
}
.dashboard__icon.--marker {
  background-image: url("~dashboard/marker.svg");
}
.dashboard__icon-hand {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
  background-image: url("~dashboard/hand.svg");
}
@media (max-width: 991px) {
  .dashboard__icon-hand {
    width: 18px;
    height: 18px;
  }
}
.dashboard__button {
  border-radius: 15px;
  border: solid 1px #304c5f;
  background-color: #ffc866;
  width: 44px;
  height: 44px;
}
@media (max-width: 575px) {
  .dashboard__button {
    display: none;
  }
}
.dashboard .lesson__card__item {
  align-items: center;
}
.dashboard .lesson__card__item__info {
  display: flex-direction;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.dashboard .lesson__card__item__group {
  max-height: unset;
}
@media (max-width: 575px) {
  .dashboard .lesson__card__item__group {
    width: 100%;
  }
}
.dashboard .lesson__card__item__image {
  width: 147px;
}
@media (max-width: 767px) {
  .dashboard .lesson__card__item__image {
    width: 100%;
    height: 155px;
  }
}
.dashboard .lesson__card__item:hover {
  border: none;
  background-color: #f1f1f1;
  padding: 10px 15px;
}
.dashboard .progressing {
  width: 98%;
}
.dashboard .progressing--wrapper {
  max-width: 100%;
  left: -1px;
  top: -1px;
  right: 0;
}
.dashboard .progressing--completed {
  margin-top: 0;
}
.dashboard .progressing--completed.--badge {
  width: var(--width-reward);
  background-color: #f1f1f1;
}
.dashboard .progressing--bar {
  border-radius: 4px;
  border: solid 1px #304c5f;
  background-color: #0085c3;
  width: 8px;
  height: 40px;
  position: absolute;
  top: -10px;
}
.dashboard .progressing--bar:hover {
  cursor: pointer;
}
.dashboard .progressing--bar.--badge {
  left: calc(var(--width-reward) - 8px);
}
.dashboard__rewards {
  min-width: 900px;
}
.dashboard__rewards__icon {
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dashboard__rewards__icon.--bronze {
  background-image: url("~dashboard/bronze.svg");
}
.dashboard__rewards__icon.--colorSilver {
  background-image: url("~dashboard/colorSilver.svg");
}
.dashboard__rewards__icon.--colorGold {
  background-image: url("~dashboard/colorGold.svg");
}
.dashboard__rewards__icon.--platinum {
  background-image: url("~dashboard/platinum.svg");
}
.dashboard__rewards__icon.--diamond {
  background-image: url("~dashboard/diamond.svg");
}
.dashboard__rewards .align-items-right {
  align-items: end;
}

.my-subscription {
  padding-top: 40px;
}
@media (max-width: 575px) {
  .my-subscription {
    padding-top: 0;
  }
}
.my-subscription__progress-bar {
  width: 100%;
  height: 1.25rem;
  border-radius: 13.5px;
  border: dashed 1px #304c5f;
  background-color: #f1f1f1;
}
@media (max-width: 575px) {
  .my-subscription__progress-bar {
    height: 10px;
  }
}
.my-subscription__progress-bar__percentage {
  margin-top: -1px;
  margin-left: -1px;
  height: 20px;
  border-radius: 13.5px;
  border: solid 1px #304c5f;
  background-color: #ffc866;
}
@media (max-width: 575px) {
  .my-subscription__progress-bar__percentage {
    height: 10px;
  }
}
.my-subscription__card {
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 20px;
}
@media (max-width: 575px) {
  .my-subscription__card {
    padding: 20px 15px;
  }
}
.my-subscription__credit-card__icon {
  background-image: url("~icon/payment/visa-blue.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 43px;
  height: auto;
  margin-right: 15px;
}
.my-subscription__credit-card__icon[name=mastercard] {
  background-image: url("~icon/payment/master-card.svg");
}
.my-subscription__credit-card__icon[name=amex] {
  background-image: url("~icon/payment/amex-card.svg");
}
.my-subscription__credit-card__icon[name=unionpay] {
  background-image: url("~icon/payment/union-pay-card.png");
}
.my-subscription__plan-detail {
  margin-bottom: 0px;
  font-weight: 700 !important;
  align-items: center;
}
.my-subscription__plan-detail i.fas {
  line-height: none;
}
.my-subscription__plan-summary {
  background-color: #f9f9f9;
  width: 170px;
  height: 150px;
  border-radius: 20px;
  position: relative;
  margin: auto;
}
.my-subscription__plan-summary__wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.my-subscription__plan-summary .field__radio__label {
  border-radius: 20px;
}
.my-subscription__plan-summary .field__radio__check {
  opacity: 0.01;
  z-index: 100;
}
.my-subscription__plan-summary .field__radio__check:checked + .field__radio__label {
  border: 2px solid;
  border-color: inherit;
}
.my-subscription__plan-summary .field__radio__check, .my-subscription__plan-summary .field__radio__label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.my-subscription__card-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.my-subscription__card-summary .field__radio__label {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  cursor: pointer;
}
.my-subscription__card-summary .field__radio__label:before {
  margin-right: 15px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  background-color: transparent;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #f0f0f0;
}
.my-subscription__card-summary .field__radio__label__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  align-items: center;
  padding: 0px 20px;
  border-radius: 10px;
}
.my-subscription__card-summary .field__radio__label__brand {
  display: flex;
  align-items: center;
}
.my-subscription__card-summary .field__radio__label__brand__image {
  background-image: url("~icon/payment/visa-card.svg");
  background-size: contain;
  width: 48px;
  height: 30px;
  margin-right: 15px;
}
.my-subscription__card-summary .field__radio__label__brand__image[name=mastercard] {
  background-image: url("~icon/payment/master-card.svg");
}
.my-subscription__card-summary .field__radio__label__brand__image[name=amex] {
  background-image: url("~icon/payment/amex-card.svg");
}
.my-subscription__card-summary .field__radio__label__brand__image[name=unionpay] {
  background-image: url("~icon/payment/union-pay-card.png");
}
.my-subscription__card-summary .field__radio__check {
  display: none;
}
.my-subscription__card-summary .field__radio__check:checked + .field__radio__label .field__radio__label__wrapper {
  background-color: #f9f9f9;
}
.my-subscription__card-summary .field__radio__check:checked + .field__radio__label:after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 5px;
  content: " ";
  display: block;
  background: #088a8b;
}
.my-subscription__purchase-summary {
  background-color: #f9f9f9;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 370px;
  max-height: 329px;
  border-radius: 20px;
  margin-left: auto;
  margin-top: 150px;
}
@media (max-width: 575px) {
  .my-subscription__purchase-summary {
    margin-top: 0;
  }
}
.my-subscription__purchase-summary__wrapper {
  line-height: 1.88;
}
.my-subscription__purchase-summary__wrapper .summary-line {
  display: flex;
  justify-content: space-between;
}
.my-subscription__purchase-summary__wrapper .summary-line #total-value {
  color: #088a8b;
}
.my-subscription__purchase-summary__wrapper .divider {
  border-bottom: 1px solid #ebebeb;
  margin: 20px 0px;
}
.my-subscription__form-group {
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 0px;
}
@media (max-width: 575px) {
  .my-subscription__form-group {
    padding-top: 0;
  }
}
.my-subscription__modal {
  color: #304c5f;
  border-radius: 10px;
  font-size: 16px;
  border: solid 1px #304c5f;
  box-shadow: 5px 5px #acacac, 5px 5px 0px 1px #304c5f;
}
.my-subscription__modal__button {
  min-width: 100px !important;
  width: 100px !important;
}
.my-subscription .payment-row__title {
  font-size: 18px;
}
.my-subscription .row.payment-row {
  padding-top: 100px;
}
.my-subscription .row.payment-row__title {
  font-size: 18px;
}
.my-subscription .row button:not(.nav-link) {
  height: 46px;
  width: 170px;
}
@media (max-width: 575px) {
  .my-subscription .row button:not(.nav-link) {
    min-width: 170px;
    width: unset;
  }
}
.my-subscription .row__select-plan {
  margin-top: 30px;
}
.my-subscription .row__list-plan {
  margin-top: 20px;
}
.my-subscription .row__list-plan__plans {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}
@media (max-width: 991px) {
  .my-subscription .row__list-plan__plans {
    grid-template-columns: repeat(2, 1fr);
  }
}
.my-subscription .row__list-plan span .field__radio__label {
  width: 100%;
  cursor: pointer;
}
.my-subscription .row__list-plan span:nth-child(3n+2) {
  color: #0085c3;
}
.my-subscription .row__list-plan span:nth-child(3n+3) {
  color: #fa744e;
}
.my-subscription .row__list-plan span:nth-child(3n+4) {
  color: #088a8b;
}
.my-subscription__currency-flag-wrapper {
  width: 2rem;
}
.my-subscription__currency-selector {
  cursor: pointer;
}
.my-subscription__currency-modal {
  border-radius: 30px !important;
  font-size: 16px;
}
.my-subscription__currency-modal__option-button {
  cursor: pointer;
  border-radius: 50px;
  padding: 12px 27px 12px 28px;
  border: solid 2px transparent;
}
.my-subscription__currency-modal__option-button:hover {
  border: dotted 2px #fa744e;
}
.my-subscription__currency-modal__option-check:checked + .my-subscription__currency-modal__option-button {
  border: solid 2px #fa744e;
}
.my-subscription__currency-modal__header {
  border-bottom: none !important;
  padding: 25px 40px !important;
}
.my-subscription__currency-modal__header__search-box {
  border-radius: 10px !important;
  padding: 14px 20px;
  border: solid 1px #ebebeb;
  background-color: #f9f9f9;
}
.my-subscription__currency-modal__header__search-icon {
  z-index: 5;
  top: calc(50%);
  transform: translateY(-50%);
  color: #304c5f;
}
.my-subscription__currency-modal__body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  max-height: 300px;
  overflow-y: auto;
}
.my-subscription__currency-modal__footer {
  border-top: none !important;
  padding: 25px 40px !important;
}

#new-subscription-otp a {
  cursor: pointer;
}

.my-transaction {
  padding-top: 40px;
}
@media (max-width: 575px) {
  .my-transaction {
    padding-top: 0;
  }
}
.my-transaction__table__thead__tr__th {
  font-family: "Nunito";
  font-size: 1rem;
  color: #304c5f;
  font-weight: normal;
  padding: 20px 15px;
}
.my-transaction__table__thead__tr__th:first-child {
  padding-left: 30px;
  width: 230px;
}
.my-transaction__table__thead__tr__th:nth-child(3) {
  width: 150px;
}
.my-transaction__table__thead__tr__th:nth-child(4) {
  width: 100px;
}
.my-transaction__table__thead__tr__th:last-child {
  padding-right: 30px;
  width: 100px;
}
.my-transaction__table__tbody__tr {
  border-radius: 20px;
  font-family: "Nunito";
  font-size: 1rem;
  font-weight: bold;
  color: #304c5f;
}
.my-transaction__table__tbody__tr:not(:nth-child(2n)) {
  background-color: #f9f9f9;
}
.my-transaction__table__tbody__tr td:not(.white-space-cell) {
  padding: 20px 15px;
  width: fit-content;
}
.my-transaction__table__tbody__tr td:not(.white-space-cell):first-child {
  padding-left: 30px;
  border-radius: 20px 0 0 20px;
}
.my-transaction__table__tbody__tr td:not(.white-space-cell):last-child {
  padding-right: 30px;
  border-radius: 0 20px 20px 0;
}
.my-transaction__card-brand {
  background-image: url("~icon/payment/visa-card.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 20px;
  margin-right: 5px;
}
.my-transaction__card-brand[name=mastercard] {
  background-image: url("~icon/payment/master-card.svg");
}
.my-transaction__card-brand[name=amex] {
  background-image: url("~icon/payment/amex-card.svg");
}
.my-transaction__card-brand[name=unionpay] {
  background-image: url("~icon/payment/union-pay-card.png");
}

.profile {
  padding-top: 40px;
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  .profile {
    padding-top: 20px;
  }
}
.profile__tab {
  width: 200px;
  color: #304c5f;
  margin-top: 10px;
  text-decoration: none;
  padding: 9px 20px;
}
.profile__tab.--active {
  border-radius: 8px;
  border: solid 1px #304c5f;
  background-color: #ffc866;
  pointer-events: none;
}
.profile__card {
  padding: 30px;
  border-radius: 20px;
  background-color: #f9f9f9;
}
@media (max-width: 767px) {
  .profile__card {
    padding: 20px 15px;
  }
}
.profile__referral-code {
  padding: 12px 20px;
  font-weight: 600;
  margin: 10px 0 5px 0;
  border-radius: 10px;
  border: solid 1px #304c5f;
  box-shadow: 5px 5px #0085c3, 5px 5px 0px 1px #304c5f;
}
.profile__share-btn {
  margin-top: 20px;
  border-radius: 5px;
  border: solid 1px #304c5f;
  background-color: #FFFFFF;
  padding: 8px 12px 7px 13px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #304c5f;
}
.profile__copy-icon {
  background-size: contain;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  background-image: url("~icon/copy.svg");
}

.cart__card {
  border-radius: 8px;
  padding: 20px;
  background-color: #f1f1f1;
  margin-bottom: 15px;
}
@media (max-width: 575px) {
  .cart__card {
    padding: 15px;
  }
}
.cart__item {
  display: flex;
  grid-column-gap: 15px;
  justify-content: space-between;
}
.cart__item__info {
  flex: 1;
}
.cart__item__info__remove-cart {
  border: none;
}
.cart__item__group {
  width: 150px;
  height: 80px;
  border-radius: 3.9px;
}
.cart__total {
  background-color: #fa6464;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart__title {
  padding-top: 40px;
  padding-bottom: 30px;
  align-items: center;
  grid-column-gap: 15px;
  display: flex;
}
@media (max-width: 575px) {
  .cart__title {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
.cart__summary {
  padding: 30px 30px 20px 30px;
  border-radius: 20px;
  background-color: #f9f9f9;
}

.two-factor-authentication {
  padding-top: 40px;
  padding-bottom: 30px;
}
.two-factor-authentication__card {
  padding: 30px;
  border-radius: 20px;
  background-color: #f9f9f9;
}

.leaderboard {
  max-width: 570px;
  margin: auto;
  border-radius: 20px;
  padding: 30px;
  background-image: url("~dashboard/leaderboard.jpeg");
}
@media (max-width: 767px) {
  .leaderboard {
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px;
  }
}
.leaderboard__top1 {
  border: solid 2px #ffc866;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .leaderboard__top1 {
    width: 80px;
    height: 80px;
  }
}
.leaderboard__avatar-top {
  border: solid 2px #fa744e;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.leaderboard__avatar-top.--three {
  border: solid 2px #0085c3;
}
@media (max-width: 767px) {
  .leaderboard__avatar-top {
    width: 60px;
    height: 60px;
  }
}
.leaderboard__group {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  position: relative;
}
.leaderboard__top {
  padding-top: 60px;
  display: flex;
  justify-content: space-around;
  background-image: url("~dashboard/leaderboard-top.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.leaderboard__top__number {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  font-weight: bold;
  top: 90px;
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .leaderboard__top__number {
    width: 20px;
    height: 20px;
    font-size: 12px;
    top: 70px;
  }
}
.leaderboard__top__number.--one {
  background-color: #ffc866;
  top: 115px;
  color: #304c5f;
}
@media (max-width: 767px) {
  .leaderboard__top__number.--one {
    top: 80px;
  }
}
.leaderboard__top__number.--two {
  background-color: #fa744e;
}
.leaderboard__top__number.--three {
  background-color: #0085c3;
}
.leaderboard__current-rank {
  display: flex;
  justify-content: space-between;
  color: #FFFFFF;
  padding: 14px 20px;
  border-radius: 10px;
  background-image: linear-gradient(to left, #0085c3, #2da4dc);
}
.leaderboard__avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}
.leaderboard__position {
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  width: 20px;
  align-items: center;
}
.leaderboard__icon__one {
  height: 25px;
  width: 25px;
  margin-bottom: 10px;
  margin-top: -25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~dashboard/leaderboard-top-icon.png");
}
.leaderboard__icon__up {
  color: #46efbf;
}
.leaderboard__icon__down {
  color: #fa6464;
}

.discussions .course-container {
  height: 220px;
  padding: 5px;
}
@media (max-width: 991px) {
  .discussions .course-container {
    height: unset;
    padding: 5px;
  }
}
.discussions .course-container__image {
  border-radius: 10px;
  height: 200px;
  max-width: 350px;
  object-fit: cover;
}
.discussions .course-container__description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 24px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-decoration: none;
}
.discussions .course-container:hover {
  border-radius: 10px;
  border: 2px solid #fa744e;
  height: 220px;
  padding: 3px;
}
.discussions .discussion-box {
  border-radius: 10px;
  border: 1px solid black;
  padding: 20px;
  display: block;
  background-color: #f9f9f9;
  display: flex;
  height: 700px;
  align-items: end;
}
.discussions .discussion-box .messages {
  max-height: 500px;
}
.discussions .discussion-box .message-box {
  border-radius: 20px;
  max-width: 75%;
}
.discussions .discussion-box .message-box__bg-start {
  background-color: #868282;
}
.discussions .discussion-box .message-box__bg-end {
  background-color: #3b8af3;
}
.discussions .discussion-box .message-box .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}
.discussions .discussion-box .message-box__time {
  font-size: 10px;
  font-style: italic;
}
.discussions .discussion-box .img-plan {
  position: absolute;
  top: 30%;
  right: 25px;
}
.discussions .discussion-box .borderClass {
  border: 2px solid #fa744e;
}
.discussions .discussion-box .chat-message {
  min-height: 3rem;
}
.discussions__students_card {
  cursor: pointer;
  padding: 11px 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  border: 1px solid transparent;
  transition: all ease 0.2s;
}
.discussions__students_card__title {
  cursor: pointer;
}
.discussions__students_card:hover {
  border: 1px solid #304c5f;
}
.discussions__students_card__item {
  display: flex;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all ease 0.2s;
}
.discussions__students_card__item:hover {
  border: 1px solid #304c5f;
  background-color: #ffc866;
}
.discussions__students_card__item__image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}
@media (max-width: 575px) {
  .discussions__students_card__item__image {
    height: 100%;
  }
}
.discussions__students_card__item__noti {
  background-color: #fa744e;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  border: 2px solid white;
  top: 10px;
  margin-top: -10px;
  margin-right: -10px;
}
.discussions .--active {
  background-color: #ffc866;
  border-radius: 10px;
}
.discussions .noti_icon {
  color: white;
  width: 25px;
  height: 25px;
  background-color: #fa744e;
  border-radius: 50%;
  position: relative;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin-top: -5px;
}
.discussions .noti_icon__cicle {
  position: absolute;
  border: 2px solid white;
  border-radius: 50%;
  color: #fa744e;
}
.discussions .lessons-box {
  background-color: #ebebeb;
  border-radius: 10px;
  border: 2px solid #FFFFFF;
  height: 140px;
}
.discussions .lessons-box__noti_messages {
  margin: auto;
  color: #fa744e;
}
.discussions .lessons-box__limit_text {
  display: -webkit-box;
  line-height: 24px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.discussions .lessons-box:hover {
  border-radius: 10px;
  border: 2px solid #fa744e;
  height: 140px;
}

.custom-confirm-modal {
  margin-top: 150px !important;
}
.custom-confirm-modal .custom-completed-button {
  border: 1px solid;
  border-radius: 7px;
  font-weight: bold;
}
.custom-confirm-modal__content {
  height: 300px;
  padding: 20px;
}
.custom-confirm-modal__content__header {
  border: none !important;
  justify-content: flex-end !important;
}
.custom-confirm-modal__content__header__cancel-btn {
  font-size: 20px;
  width: 40px;
  height: 35px;
}
.custom-confirm-modal__content__body {
  text-align: center;
}
.custom-confirm-modal__content__body__confirm-content {
  font-size: 23px;
  font-weight: bold;
}
.custom-confirm-modal__content__body__wrapper-button-modal {
  text-align: center;
  margin-top: 50px;
  display: inline-flex;
}
.custom-confirm-modal__content__body__wrapper-button-modal__confirm-button {
  width: 120px;
  height: 45px;
  margin-right: 20px;
}

.homeworks .thumnail {
  height: 150px;
  width: 150px;
  max-height: 180px;
}

/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0085c3;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fa744e;
  --bs-yellow: #ffc107;
  --bs-green: #088a8b;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #FFFFFF;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #fa754e;
  --bs-secondary: #f9f9f9;
  --bs-success: #088a8b;
  --bs-info: #0071a6;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #304c5f;
  --bs-primary-rgb: 250, 117, 78;
  --bs-secondary-rgb: 249, 249, 249;
  --bs-success-rgb: 8, 138, 139;
  --bs-info-rgb: 0, 113, 166;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 48, 76, 95;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 48, 76, 95;
  --bs-body-rgb: 48, 76, 95;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #304c5f;
  --bs-body-bg: #FFFFFF;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(48, 76, 95, 0);
}

hr {
  margin: 1rem 0;
  color: #f0f0f0;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.4125rem + 1.95vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.875rem;
  }
}

h2, .h2 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.875rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1.125rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #fa754e;
  text-decoration: underline;
}
a:hover {
  color: #c85e3e;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #FFFFFF;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #304c5f;
  --bs-table-striped-bg: rgba(48, 76, 95, 0.05);
  --bs-table-active-color: #304c5f;
  --bs-table-active-bg: rgba(48, 76, 95, 0.1);
  --bs-table-hover-color: #304c5f;
  --bs-table-hover-bg: rgba(48, 76, 95, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #304c5f;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #fee3dc;
  --bs-table-striped-bg: #f4dbd6;
  --bs-table-striped-color: #304c5f;
  --bs-table-active-bg: #e9d4d0;
  --bs-table-active-color: #304c5f;
  --bs-table-hover-bg: #efd8d3;
  --bs-table-hover-color: #304c5f;
  color: #304c5f;
  border-color: #e9d4d0;
}

.table-secondary {
  --bs-table-bg: #fefefe;
  --bs-table-striped-bg: #f4f5f6;
  --bs-table-striped-color: #304c5f;
  --bs-table-active-bg: #e9ecee;
  --bs-table-active-color: #304c5f;
  --bs-table-hover-bg: #eff1f2;
  --bs-table-hover-color: #304c5f;
  color: #304c5f;
  border-color: #e9ecee;
}

.table-success {
  --bs-table-bg: #cee8e8;
  --bs-table-striped-bg: #c6e0e1;
  --bs-table-striped-color: #304c5f;
  --bs-table-active-bg: #bed8da;
  --bs-table-active-color: #304c5f;
  --bs-table-hover-bg: #c2dcde;
  --bs-table-hover-color: #304c5f;
  color: #304c5f;
  border-color: #bed8da;
}

.table-info {
  --bs-table-bg: #cce3ed;
  --bs-table-striped-bg: #c4dbe6;
  --bs-table-striped-color: #304c5f;
  --bs-table-active-bg: #bcd4df;
  --bs-table-active-color: #304c5f;
  --bs-table-hover-bg: #c0d8e2;
  --bs-table-hover-color: #304c5f;
  color: #304c5f;
  border-color: #bcd4df;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f5ebc8;
  --bs-table-striped-color: #304c5f;
  --bs-table-active-bg: #eae2c2;
  --bs-table-active-color: #304c5f;
  --bs-table-hover-bg: #efe6c5;
  --bs-table-hover-color: #304c5f;
  color: #304c5f;
  border-color: #eae2c2;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eed0d4;
  --bs-table-striped-color: #304c5f;
  --bs-table-active-bg: #e4c9ce;
  --bs-table-active-color: #304c5f;
  --bs-table-hover-bg: #e9cdd1;
  --bs-table-hover-color: #304c5f;
  color: #304c5f;
  border-color: #e4c9ce;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #eef0f2;
  --bs-table-striped-color: #304c5f;
  --bs-table-active-bg: #e4e8eb;
  --bs-table-active-color: #304c5f;
  --bs-table-hover-bg: #e9ecee;
  --bs-table-hover-color: #304c5f;
  color: #304c5f;
  border-color: #e4e8eb;
}

.table-dark {
  --bs-table-bg: #304c5f;
  --bs-table-striped-bg: #3a5567;
  --bs-table-striped-color: #FFFFFF;
  --bs-table-active-bg: #455e6f;
  --bs-table-active-color: #FFFFFF;
  --bs-table-hover-bg: #40596b;
  --bs-table-hover-color: #FFFFFF;
  color: #FFFFFF;
  border-color: #455e6f;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #304c5f;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #304c5f;
  background-color: #FFFFFF;
  border-color: #fdbaa7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #304c5f;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #304c5f;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #304c5f;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #304c5f;
  background-color: #FFFFFF;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #fdbaa7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #304c5f;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(48, 76, 95, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #fdbaa7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}
.form-check-input:checked {
  background-color: #fa754e;
  border-color: #fa754e;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #fa754e;
  border-color: #fa754e;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fdbaa7'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFFFFF'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #fa754e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #fed6ca;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #fa754e;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #fed6ca;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #304c5f;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #088a8b;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #FFFFFF;
  background-color: rgba(8, 138, 139, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #088a8b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23088a8b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #088a8b;
  box-shadow: 0 0 0 0.25rem rgba(8, 138, 139, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #088a8b;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23088a8b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #088a8b;
  box-shadow: 0 0 0 0.25rem rgba(8, 138, 139, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #088a8b;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #088a8b;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(8, 138, 139, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #088a8b;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #FFFFFF;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #304c5f;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #304c5f;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #304c5f;
  background-color: #fa754e;
  border-color: #fa754e;
}
.btn-primary:hover {
  color: #304c5f;
  background-color: #fb8a69;
  border-color: #fb8360;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #304c5f;
  background-color: #fb8a69;
  border-color: #fb8360;
  box-shadow: 0 0 0 0.25rem rgba(220, 111, 81, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #304c5f;
  background-color: #fb9171;
  border-color: #fb8360;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 111, 81, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #304c5f;
  background-color: #fa754e;
  border-color: #fa754e;
}

.btn-secondary {
  color: #304c5f;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
.btn-secondary:hover {
  color: #304c5f;
  background-color: #fafafa;
  border-color: #fafafa;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #304c5f;
  background-color: #fafafa;
  border-color: #fafafa;
  box-shadow: 0 0 0 0.25rem rgba(219, 223, 226, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #304c5f;
  background-color: #fafafa;
  border-color: #fafafa;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(219, 223, 226, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #304c5f;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}

.btn-success {
  color: #FFFFFF;
  background-color: #088a8b;
  border-color: #088a8b;
}
.btn-success:hover {
  color: #FFFFFF;
  background-color: #077576;
  border-color: #066e6f;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #FFFFFF;
  background-color: #077576;
  border-color: #066e6f;
  box-shadow: 0 0 0 0.25rem rgba(45, 156, 156, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #FFFFFF;
  background-color: #066e6f;
  border-color: #066868;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(45, 156, 156, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #FFFFFF;
  background-color: #088a8b;
  border-color: #088a8b;
}

.btn-info {
  color: #FFFFFF;
  background-color: #0071a6;
  border-color: #0071a6;
}
.btn-info:hover {
  color: #FFFFFF;
  background-color: #00608d;
  border-color: #005a85;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #FFFFFF;
  background-color: #00608d;
  border-color: #005a85;
  box-shadow: 0 0 0 0.25rem rgba(38, 134, 179, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #FFFFFF;
  background-color: #005a85;
  border-color: #00557d;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 134, 179, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #FFFFFF;
  background-color: #0071a6;
  border-color: #0071a6;
}

.btn-warning {
  color: #304c5f;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #304c5f;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #304c5f;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(224, 175, 20, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #304c5f;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(224, 175, 20, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #304c5f;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #FFFFFF;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #FFFFFF;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #FFFFFF;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #FFFFFF;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #FFFFFF;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #304c5f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #304c5f;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #304c5f;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(218, 223, 227, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #304c5f;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 223, 227, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #304c5f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #FFFFFF;
  background-color: #304c5f;
  border-color: #304c5f;
}
.btn-dark:hover {
  color: #FFFFFF;
  background-color: #294151;
  border-color: #263d4c;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #FFFFFF;
  background-color: #294151;
  border-color: #263d4c;
  box-shadow: 0 0 0 0.25rem rgba(79, 103, 119, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #FFFFFF;
  background-color: #263d4c;
  border-color: #243947;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(79, 103, 119, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #FFFFFF;
  background-color: #304c5f;
  border-color: #304c5f;
}

.btn-outline-primary {
  color: #fa754e;
  border-color: #fa754e;
}
.btn-outline-primary:hover {
  color: #304c5f;
  background-color: #fa754e;
  border-color: #fa754e;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #304c5f;
  background-color: #fa754e;
  border-color: #fa754e;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #fa754e;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #f9f9f9;
  border-color: #f9f9f9;
}
.btn-outline-secondary:hover {
  color: #304c5f;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(249, 249, 249, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #304c5f;
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(249, 249, 249, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #f9f9f9;
  background-color: transparent;
}

.btn-outline-success {
  color: #088a8b;
  border-color: #088a8b;
}
.btn-outline-success:hover {
  color: #FFFFFF;
  background-color: #088a8b;
  border-color: #088a8b;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(8, 138, 139, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #088a8b;
  border-color: #088a8b;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(8, 138, 139, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #088a8b;
  background-color: transparent;
}

.btn-outline-info {
  color: #0071a6;
  border-color: #0071a6;
}
.btn-outline-info:hover {
  color: #FFFFFF;
  background-color: #0071a6;
  border-color: #0071a6;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 113, 166, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #0071a6;
  border-color: #0071a6;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 113, 166, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0071a6;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #304c5f;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #304c5f;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #FFFFFF;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #304c5f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #304c5f;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #304c5f;
  border-color: #304c5f;
}
.btn-outline-dark:hover {
  color: #FFFFFF;
  background-color: #304c5f;
  border-color: #304c5f;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(48, 76, 95, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #FFFFFF;
  background-color: #304c5f;
  border-color: #304c5f;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(48, 76, 95, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #304c5f;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #fa754e;
  text-decoration: underline;
}
.btn-link:hover {
  color: #c85e3e;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #304c5f;
  text-align: left;
  list-style: none;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(48, 76, 95, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(48, 76, 95, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #FFFFFF;
  text-decoration: none;
  background-color: #fa754e;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(48, 76, 95, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #FFFFFF;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #FFFFFF;
  background-color: #fa754e;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(48, 76, 95, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #fa754e;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #c85e3e;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #FFFFFF;
  border-color: #dee2e6 #dee2e6 #FFFFFF;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFFFFF;
  background-color: #fa754e;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: #fa744e;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #fa744e;
}
.navbar-light .navbar-nav .nav-link {
  color: #2f2f2f;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(48, 76, 95, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(48, 76, 95, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #fa744e;
}
.navbar-light .navbar-toggler {
  color: #2f2f2f;
  border-color: rgba(48, 76, 95, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%232f2f2f' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #2f2f2f;
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #fa744e;
}

.navbar-dark .navbar-brand {
  color: #FFFFFF;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #FFFFFF;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #FFFFFF;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: border-box;
  border: 1px solid rgba(48, 76, 95, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(48, 76, 95, 0.03);
  border-bottom: 1px solid rgba(48, 76, 95, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(48, 76, 95, 0.03);
  border-top: 1px solid rgba(48, 76, 95, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #304c5f;
  text-align: left;
  background-color: #FFFFFF;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #e16946;
  background-color: #fff1ed;
  box-shadow: inset 0 -1px 0 rgba(48, 76, 95, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e16946'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23304c5f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #fdbaa7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #FFFFFF;
  border: 1px solid rgba(48, 76, 95, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #fa754e;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #c85e3e;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #c85e3e;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #FFFFFF;
  background-color: #fa754e;
  border-color: #fa754e;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #FFFFFF;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #96462f;
  background-color: #fee3dc;
  border-color: #fed6ca;
}
.alert-primary .alert-link {
  color: #783826;
}

.alert-secondary {
  color: #80919d;
  background-color: #fefefe;
  border-color: #fdfdfd;
}
.alert-secondary .alert-link {
  color: #66747e;
}

.alert-success {
  color: #055353;
  background-color: #cee8e8;
  border-color: #b5dcdc;
}
.alert-success .alert-link {
  color: #044242;
}

.alert-info {
  color: #004464;
  background-color: #cce3ed;
  border-color: #b3d4e4;
}
.alert-info .alert-link {
  color: #003650;
}

.alert-warning {
  color: #837b3c;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #696230;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #80919d;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #66747e;
}

.alert-dark {
  color: #1d2e39;
  background-color: #d6dbdf;
  border-color: #c1c9cf;
}
.alert-dark .alert-link {
  color: #17252e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #FFFFFF;
  text-align: center;
  white-space: nowrap;
  background-color: #fa754e;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #304c5f;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 1px solid rgba(48, 76, 95, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #FFFFFF;
}
.list-group-item.active {
  z-index: 2;
  color: #FFFFFF;
  background-color: #fa754e;
  border-color: #fa754e;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #96462f;
  background-color: #fee3dc;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #96462f;
  background-color: #e5ccc6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #96462f;
  border-color: #96462f;
}

.list-group-item-secondary {
  color: #80919d;
  background-color: #fefefe;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #80919d;
  background-color: #e5e5e5;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #80919d;
  border-color: #80919d;
}

.list-group-item-success {
  color: #055353;
  background-color: #cee8e8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #055353;
  background-color: #b9d1d1;
}
.list-group-item-success.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #055353;
  border-color: #055353;
}

.list-group-item-info {
  color: #004464;
  background-color: #cce3ed;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #004464;
  background-color: #b8ccd5;
}
.list-group-item-info.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #004464;
  border-color: #004464;
}

.list-group-item-warning {
  color: #837b3c;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #837b3c;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #837b3c;
  border-color: #837b3c;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #80919d;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #80919d;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #80919d;
  border-color: #80919d;
}

.list-group-item-dark {
  color: #1d2e39;
  background-color: #d6dbdf;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1d2e39;
  background-color: #c1c5c9;
}
.list-group-item-dark.list-group-item-action.active {
  color: #FFFFFF;
  background-color: #1d2e39;
  border-color: #1d2e39;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #304c5f;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23304c5f'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #304c5f;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(250, 117, 78, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(48, 76, 95, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(48, 76, 95, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #304c5f;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #304c5f;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #304c5f;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #304c5f;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #304c5f;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  text-align: center;
  background-color: #304c5f;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid rgba(48, 76, 95, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(48, 76, 95, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #FFFFFF;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(48, 76, 95, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #FFFFFF;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(48, 76, 95, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #FFFFFF;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(48, 76, 95, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #FFFFFF;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(48, 76, 95, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #304c5f;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFFFFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFFFFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFFFFF;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #304c5f;
}
.carousel-dark .carousel-caption {
  color: #304c5f;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #FFFFFF;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #304c5f;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(48, 76, 95, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(48, 76, 95, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(48, 76, 95, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(48, 76, 95, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #304c5f 55%, rgba(0, 0, 0, 0.8) 75%, #304c5f 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #fa754e;
}
.link-primary:hover, .link-primary:focus {
  color: #fb9171;
}

.link-secondary {
  color: #f9f9f9;
}
.link-secondary:hover, .link-secondary:focus {
  color: #fafafa;
}

.link-success {
  color: #088a8b;
}
.link-success:hover, .link-success:focus {
  color: #066e6f;
}

.link-info {
  color: #0071a6;
}
.link-info:hover, .link-info:focus {
  color: #005a85;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #304c5f;
}
.link-dark:hover, .link-dark:focus {
  color: #263d4c;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(48, 76, 95, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(48, 76, 95, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(48, 76, 95, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #fa754e !important;
}

.border-secondary {
  border-color: #f9f9f9 !important;
}

.border-success {
  border-color: #088a8b !important;
}

.border-info {
  border-color: #0071a6 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #304c5f !important;
}

.border-white {
  border-color: #FFFFFF !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.4125rem + 1.95vw) !important;
}

.fs-2 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-3 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1.125rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(48, 76, 95, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.875rem !important;
  }

  .fs-2 {
    font-size: 2.5rem !important;
  }

  .fs-3 {
    font-size: 1.875rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}