.leaderboard {
  max-width: 570px;
  margin: auto;
  border-radius: 20px;
  padding: 30px;
  background-image: url('~dashboard/leaderboard.jpeg');
  @include respond-below(sm) {
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px;
  }
  &__top1 {
    border: solid 2px $golden;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;

    @include respond-below(sm) {
      width: 80px;
      height: 80px;
    }
  }
  &__avatar-top {
    border: solid 2px $orange;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;

    &.--three {
      border: solid 2px $blue;
    }
    @include respond-below(sm) {
      width: 60px;
      height: 60px;
    }
  }
  &__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    position: relative;
  }
  &__top {
    padding-top: 60px;
    display: flex;
    justify-content: space-around;
    background-image: url('~dashboard/leaderboard-top.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &__number {
      border-radius: 50%;
      width: 28px;
      height: 28px;
      position: absolute;
      font-weight: bold;
      top: 90px;
      color: $white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond-below(sm) {
        width: 20px;
        height: 20px;
        font-size: 12px;
        top: 70px;
      }
      &.--one {
        background-color: $golden;
        top: 115px;
        color: $black;
        @include respond-below(sm) {
          top: 80px;
        }
      }
      &.--two {
        background-color: $orange;
      }
      &.--three {
        background-color: $blue;
      }
    }
  }
  &__current-rank {
    display: flex;
    justify-content: space-between;
    color: $white;
    padding: 14px 20px;
    border-radius: 10px;
    background-image: linear-gradient(to left, #0085c3, #2da4dc);
  }
  &__avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__position {
    color: $white;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    width: 20px;
    align-items: center;
  }
  &__icon {
    &__one {
      height: 25px;
      width: 25px;
      margin-bottom: 10px;
      margin-top: -25px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('~dashboard/leaderboard-top-icon.png');
    }
    &__up {
      color: #46efbf;
    }
    &__down {
      color: $medium-red;
    }
  }
}
