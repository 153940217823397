@use 'sass:math';

.my-courses {
  padding-bottom: 40px;
  &__empty {
    padding-top: 80px;
    @include respond-below(sm) {
      font-size: 16px !important;
    }
  }
  &__card {
    border-radius: 20px;
    background-color: $white-smoke;
    padding: 30px;
  }
  &__nav {
    font-size: 18px;
    border-bottom: 2px solid $gray92;
    .nav-link {
      padding: 0 0 20px 0;
      margin-right: 50px;
      border: none;
      color: $dark;
      font-size: 18px;
      &.active {
        border-bottom: 5px solid $green;
        border-radius: 0;
        font-weight: bold;
        &.--custom-color {
          color: $black;
          background-color: unset;
        }
      }
    }
    @include respond-below(xs) {
      padding-top: 300px;
      .nav-link {
        padding: 0 0 15px 0;
        margin-right: 15px;
        font-size: 14px;
        &.active {
          border-bottom: 3px solid $green;
        }
      }
    }
  }
  .fill-blank {
    border: 1px solid black;
    background-color: unset;
    border-radius: 10px;
  }
  &__video {
    border-radius: 20px;
    border: 15px solid $golden;
    background-color: $golden;

    .h5p-iframe-wrapper {
      display: flex;
      iframe.h5p-iframe {
        border-radius: 10px;
      }
    }
    &.--loading {
      min-height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      .h5p-player-component-root {
        width: 0;
      }
      @include respond-below(xs) {
        height: 210px;
        min-height: unset;
      }
    }
    @include respond-below(xs) {
      position: absolute;
      left: 0;
      right: 0;
      border-radius: 0;
    }
  }

  &__group-info {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px 40px;
    @include respond-below(md) {
      gap: 10px 30px;
    }
  }

  &__carousel {
    border-radius: 20px;
    padding: 30px 45px;
    background-image: url('~courses/bg-carousel.jpeg');
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 40%;
      bottom: 0;
      z-index: 1;
      width: 70px;
      height: 70px;
      background: $golden;
      border-radius: 50%;
      box-shadow: 0 0 0px 4px rgba(255, 200, 102, 0.2);
      border: none;
      &--left {
        left: -85px;
      }
      &--right {
        right: -85px;
      }
      .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23304c5f'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }

      .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23304c5f'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
      }
      @include respond-between(sm, md) {
        &--left {
          left: -80px;
        }
        &--right {
          right: -80px;
        }
      }
      @include respond-below(sm) {
        height: 39px;
        width: 39px;
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          width: 17px;
          margin-top: 2px;
        }
        &--left {
          left: -30px;
        }
        &--right {
          right: -30px;
        }
      }
    }
    @include respond-below(sm) {
      padding: 20px 15px;
    }
  }

  .modal {
    .modal-dialog {
      max-width: 670px;
      max-height: 724px;
      .modal-content {
        padding: 40px 40px 44px;
        border-radius: 30px;

        .student-info {
          display: flex;
          align-items: center;

          .avatar {
            object-fit: cover;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            margin-right: 15px;

            @include respond-below(md) {
              height: 30px;
              width: 30px;
            }
          }
        }

        .detail-info {
          margin-top: 10px;

          &__row {
            display: flex;
            justify-content: space-between;

            .key {
              margin-right: 30px;
            }
          }
        }

        .divider {
          border-bottom: 1px solid $gray92;
          margin: 20px 0px;
        }

        .detail-result {
          height: 350px;
          overflow: scroll;

          .question {
            margin-bottom: 15px;
          }

          .answers-wrapper {
            margin-bottom: 20px;

            .answer-row {
              margin-bottom: 18px;

              .answer-order {
                margin-right: 15px;
                width: 30px;
                height: 30px;
                padding: 6px 9px 5px 10px;
                border: solid 0.9px $black;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.white {
                  background-color: $white-smoke;
                }

                &.green {
                  background-color: $green;
                  color: $white-smoke;
                }

                &.red {
                  background-color: $medium-red;
                  color: $white-smoke;
                }

                &.black {
                  background-color: $black;
                  color: $white-smoke;
                }
              }
            }
          }
        }

        .filter {
          min-width: 140px;
          max-width: 150px;
          color: $black;
          height: 34px;
          padding: 6px 13px 6px 14px;
          border-radius: 8px;
          border: solid 1px $gray92;
          background-color: $white-smoke;
          margin-bottom: 15px;
        }

        .answer_try {
          border-bottom: 1px solid #dee2e6;
          margin-bottom: 1rem;
        }
      }
    }
  }

  .wrapper-user-course-lesson {
    .custom-completed-lesson {
      text-align: center;
      margin-bottom: 1.5rem;

      .disable-button-completed {
        pointer-events: none;
        opacity: 0.4;
      }

      .custom-confirm-modal {
        max-width: 550px;
      }
    }
  }
}

.progress {
  &--circle {
    position: relative;
    display: inline-block;
    margin: 20px 20px 20px 0;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: $gray92;
    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      width: 43px;
      height: 43px;
      border-radius: 50%;
      background-color: $white;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $light-red;
    }
  }
  &__number {
    position: absolute;
    top: 40%;
    width: 100%;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    font-size: $font-size-small;
    color: $light-red;
  }
}

$step: 1;
$loops: round(math.div(100, $step));
$increment: math.div(360, $loops);
$half: round(math.div($loops, 2));
@for $i from 0 through $loops {
  .progress--circle.progress--#{$i * $step}:after {
    @if $i < $half {
      $nextDeg: 90deg + ($increment * $i);
      background-image: linear-gradient(
          90deg,
          $gray92 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient($nextDeg, $light-red 50%, $gray92 50%, $gray92);
    } @else {
      $nextDeg: -90deg + ($increment * ($i - $half));
      background-image: linear-gradient(
          $nextDeg,
          $light-red 50%,
          transparent 50%,
          transparent
        ),
        linear-gradient(270deg, $light-red 50%, $gray92 50%, $gray92);
    }
  }
}

.lesson {
  &__card {
    padding: 11px 15px;
    border-radius: 8px;
    background-color: $white-smoke;
    margin-bottom: 10px;
    &__title {
      cursor: pointer;
    }
    &__item {
      display: flex;
      border-radius: 8px;
      padding: 10px 15px;
      background-color: #f1f1f1;
      margin-top: 15px;
      grid-column-gap: 15px;
      cursor: pointer;
      @include respond-below(xs) {
        flex-direction: column;
      }
      &__content {
        font-size: 14px;
        font-weight: bold;
      }
      &__group {
        position: relative;
        max-width: 150px;
        max-height: 80px;
        @include respond-below(xs) {
          max-width: 100%;
          height: 155px;
          max-height: unset;
          margin-bottom: 10px;
        }
        .play-icon {
          width: 32px;
          height: 32px;
          top: -7px;
          box-shadow: 0 0 0px 4px rgba(250, 117, 78, 0.2);
          &__triangle {
            width: 7px;
            height: 10px;
          }
        }
      }
      &__image {
        width: 150px;
        height: 80px;
        border-radius: 3.9px;
        @include respond-below(xs) {
          width: 100%;
          height: 100%;
        }
      }
      &__name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 24px;
        height: 48px;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        @include respond-below(xs) {
          height: unset;
        }
      }
      &.--active {
        background-color: $golden;
        .play-icon__triangle {
          background-image: url('~homepage/pause-icon.svg');
        }
      }
      &:hover {
        border: solid 1px $black;
        background-color: $golden;
        padding: 9px 14px;
      }
    }
    &:hover {
      border: solid 1px $black;
      padding: 10px 14px;
    }

    .disable-lesson {
      pointer-events: none;
      cursor: default;
      display: block;
    }
  }
  &__result {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    padding-bottom: 5rem;
    @include respond-below(md) {
      padding-bottom: 1rem;
    }
    &__chart {
      width: 300px;
      height: 300px;
      position: relative;
      @include respond-below(md) {
        width: 200px;
        height: 200px;
        margin-bottom: 40px;
      }
      &__text {
        text-align: center;
        top: 45%;
        left: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    }
    &__icon {
      width: 18px;
      height: 18px;
      margin: 0 8px 1px 0;
      border-radius: 50%;
      &.--correct {
        background-color: $blue;
      }
      &.--wrong {
        background-color: $medium-red;
      }
      &.--custom {
        background-color: var(--custom-color);
      }
    }
    &__legend {
      flex-direction: column;
      display: flex;
      align-self: center;
    }
    @include respond-below(md) {
      flex-direction: column;
      display: flex;
      align-items: center;
      &__legend {
        flex-direction: unset;
        justify-content: space-between;
        width: 100%;
        &.--custom {
          flex-direction: column;
        }
      }
    }
  }
  &__navigation {
    padding: 11px 15px;
    width: 100%;
    border-radius: 10px;
    background-color: $white-smoke;
    border: none;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $black;
    @include respond-above(md) {
      display: none;
    }
  }
  &--no-subscription {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
  }
}

.table-statistics {
  margin-top: 1.5rem;

  thead {
    th {
      font-weight: 400 !important;
      padding: 10px 20px;
    }

    .name {
      max-width: 600px;
      min-width: 300px;
    }
  }

  tbody {
    tr {
      margin: 10px 0px;
      background-color: $white-smoke;
      border: 10px solid $white;

      td {
        padding: 20px;
        font-weight: 700 !important;

        &:first-child {
          border-top-left-radius: 35px !important;
          border-bottom-left-radius: 35px !important;
        }

        &:last-child {
          border-top-right-radius: 35px !important;
          border-bottom-right-radius: 35px !important;
        }
      }
    }
  }
}

.readmore {
  margin-bottom: 30px;
  position: relative;
  max-height: 245px;
  overflow: hidden;
  width: 100%;
  padding: 30px;
  border-radius: 16px;
  background-color: $white-smoke;
  padding-bottom: 60px;
  transition: max-height 0.15s ease-out;
  @include respond-below(md) {
    padding: 20px;
  }
  &.--expand {
    max-height: 100%;
    transition: max-height 0.35s ease-in-out;
    padding: 30px;
    &:after {
      height: 0;
    }
    .readmore__description {
      max-height: 100%;
    }
  }
  &__link {
    font-weight: bold;
    font-size: 16px;
    padding-top: 10px;
    position: absolute;
    bottom: 30px;
    cursor: pointer;
    margin-bottom: 0;
    z-index: 1;
    width: 100%;
    padding-top: 140px;
    @include respond-below(md) {
      padding-top: 70px;
      bottom: 20px;
    }
    &.--expand {
      position: relative;
      background-image: none;
      padding-top: 20px;
      bottom: 0;
      &:after {
        content: 'Read less';
      }
    }
    &:after {
      content: 'Read more';
    }
  }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    display: block;
    background-color: inherit;
    @include respond-below(md) {
      height: 45px;
    }
  }
  &.no-after {
    padding-bottom: 30px;
    &:after {
      height: 0;
    }
  }
  &__description {
    max-height: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 24px;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    @include respond-below(md) {
      max-height: 150px;
    }
  }
}

.nav-link-course {
  text-decoration: none;
  color: $black;
  &:hover {
    color: $orange;
  }
}

.disable-button {
  pointer-events: none;
  opacity: 0.5;
}

.livestream-collapse {
  border-radius: 10px;
  border: 1px solid black;
}
.livestream-content {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: transparent !important;
}
