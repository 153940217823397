.discussions {
  .course-container {
    height: 220px;
    padding: 5px;
    @include respond-below(md) {
      height: unset;
      padding: 5px;
    }

    &__image {
      border-radius: 10px;
      height: 200px;
      max-width: 350px;
      object-fit: cover;
    }
    &__description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 24px;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-decoration: none;
    }
    &:hover {
      border-radius: 10px;
      border: 2px solid $orange;
      height: 220px;
      padding: 3px;
    }
  }

  .discussion-box {
    border-radius: 10px;
    border: 1px solid black;
    padding: 20px;
    display: block;
    background-color: #f9f9f9;
    display: flex;
    height: 700px;
    align-items: end;

    .messages {
      max-height: 500px;
    }
    .message-box {
      border-radius: 20px;
      max-width: 75%;
      &__bg-start {
        background-color: #868282;
      }
      &__bg-end {
        background-color: #3b8af3;
      }
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 5px;
        object-fit: cover;
      }
      &__time {
        font-size: 10px;
        font-style: italic;
      }
    }
    .img-plan {
      position: absolute;
      top: 30%;
      right: 25px;
    }
    .borderClass {
      border: 2px solid $orange;
    }

    .chat-message {
      min-height: 3rem;
    }
  }
  &__students_card {
    cursor: pointer;
    padding: 11px 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
    border: 1px solid transparent;
    transition: all ease 0.2s;
    &__title {
      cursor: pointer;
    }
    &:hover {
      border: 1px solid $black;
    }
    &__item {
      display: flex;
      padding: 1rem;
      border: 1px solid transparent;
      border-radius: 8px;
      transition: all ease 0.2s;
      &:hover {
        border: 1px solid $black;
        background-color: $golden;
      }
      &__image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 5px;
        @include respond-below(xs) {
          height: 100%;
        }
      }
      &__noti {
        background-color: $orange;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        position: absolute;
        border: 2px solid white;
        top: 10px;
        margin-top: -10px;
        margin-right: -10px;
      }
    }
  }
  .--active {
    background-color: $golden;
    border-radius: 10px;
  }
  .noti_icon {
    color: white;
    width: 25px;
    height: 25px;
    background-color: #fa744e;
    border-radius: 50%;
    position: relative;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    margin-top: -5px;
    &__cicle {
      position: absolute;
      border: 2px solid white;
      border-radius: 50%;
      color: $orange;
    }
  }
  .lessons-box {
    background-color: $gray92;
    border-radius: 10px;
    border: 2px solid $white;
    height: 140px;

    &__noti_messages {
      margin: auto;
      color: #fa744e;
    }
    &__limit_text {
      display: -webkit-box;
      line-height: 24px;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &:hover {
      border-radius: 10px;
      border: 2px solid $orange;
      height: 140px;
    }
  }
}
