@include respond-below(sm) {
  h1 {
    font-size: 24px !important;
  }
  h2 {
    font-size: 20px !important;
  }
  h4 {
    font-size: 18px !important;
  }
  h5 {
    font-size: 16px !important;
  }
  p, h6 {
    font-size: 14px !important;
  }
}
