.my-payment {
  padding-top: 40px;
  @include respond-below(xs) {
    padding-top: 1.5rem;
  }

  &__card {
    padding: 30px;
    background-color: $white-smoke;
    border-radius: 20px;
    &__empty {
      padding-top: 70px;
      padding-bottom: 70px;
      text-align: center;
    }
  }

  &__credit-card {
    background-image: url('~dashboard/payment/visa-card.jpeg');
    &[name='amex'] {
      background-image: url('~dashboard/payment/ae-card.jpeg');
    }
    &[name='mastercard'] {
      background-image: url('~dashboard/payment/master-card.jpeg');
    }
    &[name='unionpay'] {
      background-image: url('~dashboard/payment/unionpay-card.jpeg');
    }
    background-size: contain;
    width: 355px;
    height: 206px;
    padding: 20px;
    border-radius: 30px;
    box-shadow: 15px 15px $white-smoke;
    color: $white;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    &__title {
      color: #78afbe;
      font-size: 12px;

      &[name='amex'] {
        color: #93cdff;
        font-size: 12px;
      }
      &[name='mastercard'] {
        color: #7891de;
        font-size: 12px;
      }
      &[name='unionpay'] {
        color: #ffa5b2;
        font-size: 12px;
      }
    }
    &__icon-card {
      background-image: url('~icon/payment/visa.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 80px;
      height: 25px;
      &[name='amex'] {
        background-image: url('~icon/payment/ae-card-text.svg');
        width: 65px;
      }
      &[name='mastercard'] {
        width: 137px;
        background-image: url('~icon/payment/master-card-text.svg');
      }
      &[name='unionpay'] {
        height: 40px;
        background-image: url('~icon/payment/union-pay.svg');
      }
    }
    &__radio {
      height: 1.5rem !important;
      width: 3rem !important;
      cursor: pointer;
    }
    &__sticky-button {
      top: 1.5rem;
    }
  }
  &__icon {
    background-size: contain;
    width: 48px;
    height: 30px;
    &--visa {
      background-image: url('~icon/payment/visa-card.svg');
    }
    &--mastercard {
      background-image: url('~icon/payment/master-card.svg');
    }
    &--amex {
      background-image: url('~icon/payment/amex-card.svg');
    }
    &--unionpay {
      background-image: url('~icon/payment/union-pay-card.png');
    }
  }
}
