.my-subscription {
  padding-top: 40px;

  @include respond-below(xs) {
    padding-top: 0;
  }

  &__progress-bar {
    width: 100%;
    height: 1.25rem;
    border-radius: 13.5px;
    border: dashed 1px $black;
    background-color: #f1f1f1;

    @include respond-below(xs) {
      height: 10px;
    }

    &__percentage {
      margin-top: -1px;
      margin-left: -1px;
      height: 20px;
      border-radius: 13.5px;
      border: solid 1px $black;
      background-color: $golden;

      @include respond-below(xs) {
        height: 10px;
      }
    }
  }

  &__card {
    padding: 30px;
    background-color: $white-smoke;
    border-radius: 20px;

    @include respond-below(xs) {
      padding: 20px 15px;
    }
  }

  &__credit-card {
    &__icon {
      background-image: url('~icon/payment/visa-blue.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 43px;
      height: auto;
      margin-right: 15px;
      &[name='mastercard'] {
        background-image: url('~icon/payment/master-card.svg');
      }
      &[name='amex'] {
        background-image: url('~icon/payment/amex-card.svg');
      }
      &[name='unionpay'] {
        background-image: url('~icon/payment/union-pay-card.png');
      }
    }
  }

  &__plan-detail {
    margin-bottom: 0px;
    font-weight: 700 !important;
    align-items: center;

    i.fas {
      line-height: none;
    }
  }

  &__plan-summary {
    background-color: $white-smoke;
    width: 170px;
    height: 150px;
    border-radius: 20px;
    position: relative;
    margin: auto;

    &__wrapper {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    .field__radio {
      &__label {
        border-radius: 20px;
      }

      &__check {
        opacity: 0.01;
        z-index: 100;

        &:checked + .field__radio__label {
          border: 2px solid;
          border-color: inherit;
        }
      }

      &__check,
      &__label {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &__card-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .field__radio {
      &__label {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        cursor: pointer;

        &:before {
          margin-right: 15px;
          width: 26px;
          height: 26px;
          border-radius: 50%;
          position: relative;
          background-color: transparent;
          content: '';
          display: inline-block;
          visibility: visible;
          border: 2px solid $gray;
        }

        &__wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 56px;
          align-items: center;
          padding: 0px 20px;
          border-radius: 10px;
        }

        &__brand {
          display: flex;
          align-items: center;

          &__image {
            background-image: url('~icon/payment/visa-card.svg');
            background-size: contain;
            width: 48px;
            height: 30px;
            margin-right: 15px;
            &[name='mastercard'] {
              background-image: url('~icon/payment/master-card.svg');
            }
            &[name='amex'] {
              background-image: url('~icon/payment/amex-card.svg');
            }
            &[name='unionpay'] {
              background-image: url('~icon/payment/union-pay-card.png');
            }
          }
        }
      }

      &__check {
        display: none;

        &:checked {
          & + .field__radio__label {
            .field__radio__label__wrapper {
              background-color: $white-smoke;
            }

            &:after {
              border-radius: 50%;
              width: 16px;
              height: 16px;
              position: absolute;
              left: 5px;
              content: ' ';
              display: block;
              background: $green;
            }
          }
        }
      }
    }
  }

  &__purchase-summary {
    background-color: $white-smoke;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 370px;
    max-height: 329px;
    border-radius: 20px;
    margin-left: auto;
    margin-top: 150px;

    @include respond-below(xs) {
      margin-top: 0;
    }

    &__wrapper {
      line-height: 1.88;

      .summary-line {
        display: flex;
        justify-content: space-between;

        #total-value {
          color: $green;
        }
      }
      .divider {
        border-bottom: 1px solid $gray92;
        margin: 20px 0px;
      }
    }
  }

  &__form-group {
    border-bottom: 1px solid $gray;
    padding: 20px 0px;

    @include respond-below(xs) {
      padding-top: 0;
    }
  }

  &__modal {
    color: $black;
    border-radius: 10px;
    font-size: 16px;
    border: solid 1px $black;
    box-shadow: 5px 5px #acacac, 5px 5px 0px 1px $black;

    &__button {
      min-width: 100px !important;
      width: 100px !important;
    }
  }

  .payment-row__title {
    font-size: 18px;
  }
  .row {
    &.payment-row {
      padding-top: 100px;

      &__title {
        font-size: 18px;
      }
    }

    button:not(.nav-link) {
      height: 46px;
      width: 170px;

      @include respond-below(xs) {
        min-width: 170px;
        width: unset;
      }
    }

    &__select-plan {
      margin-top: 30px;
    }

    &__list-plan {
      margin-top: 20px;

      &__plans {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 1rem;
        row-gap: 1rem;

        @include respond-below(md) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      span {
        .field__radio__label {
          width: 100%;
          cursor: pointer;
        }

        &:nth-child(3n + 2) {
          color: $blue;
        }

        &:nth-child(3n + 3) {
          color: $orange;
        }

        &:nth-child(3n + 4) {
          color: $green;
        }
      }
    }
  }

  &__currency-flag-wrapper {
    width: 2rem;
  }

  &__currency-selector {
    cursor: pointer;
  }

  &__currency-modal {
    border-radius: 30px !important;
    font-size: 16px;

    &__option-button {
      cursor: pointer;
      border-radius: 50px;
      padding: 12px 27px 12px 28px;
      border: solid 2px transparent;

      &:hover {
        border: dotted 2px $orange;
      }
    }

    &__option-check {
      &:checked + .my-subscription__currency-modal__option-button {
        border: solid 2px $orange;
      }
    }

    &__header {
      border-bottom: none !important;
      padding: 25px 40px !important;

      &__search-box {
        border-radius: 10px !important;
        padding: 14px 20px;
        border: solid 1px $gray92;
        background-color: $white-smoke;
      }

      &__search-icon {
        z-index: 5;
        top: calc(50%);
        transform: translateY(-50%);
        color: $black;
      }
    }

    &__body {
      padding-left: 40px !important;
      padding-right: 40px !important;
      max-height: 300px;
      overflow-y: auto;
    }

    &__footer {
      border-top: none !important;
      padding: 25px 40px !important;
    }
  }
}

#new-subscription-otp a {
  cursor: pointer;
}
